const activeCoupon = {
  code: 'welcome',
  description: '首儲優惠',
  isPublic: true,
  startDate: '2021-06-27T09:46:07.000+0000',
  endDate: '2021-10-27T09:46:07.000+0000',
  couponDiscounts: [
    {
      pricingSn: '1',
      discountAmount: 0,
      rewardEmail: 0,
      offPercentage: 10,
    },
    {
      pricingSn: '2',
      discountAmount: 100,
      rewardEmail: 0,
      offPercentage: 0,
    },
    {
      pricingSn: '3',
      discountAmount: 0,
      rewardEmail: 0,
      offPercentage: 10,
    },
  ],
};

export const mockCoupons = () => [
  // activeCoupon,
  {
    code: 'welcome_old',
    description: '老朋友優惠',
    type: 0,
    startDate: '2021-06-27T09:46:07.000+0000',
    endDate: '2021-10-27T09:46:07.000+0000',
    couponDiscounts: [
      {
        pricingSn: '2',
        discountAmount: 0,
        rewardEmail: 500,
        offPercentage: 0,
      },
      {
        pricingSn: '3',
        discountAmount: 0,
        rewardEmail: 0,
        offPercentage: 10,
      },
    ],
  },
  {
    code: 'partner',
    description: '行銷老師',
    type: 2,
    startDate: '2021-11-03T16:00:00Z',
    endDate: '2021-11-05T16:00:00Z',
    couponDiscounts: [{ pricingSn: 'ALL', discountAmount: 500, rewardEmail: 0, offPercentage: 0 }],
  },
  {
    code: 'emily',
    description: '加速轉單',
    type: 1,
    startDate: '2021-11-03T16:00:00Z',
    endDate: '2021-11-05T16:00:00Z',
    couponDiscounts: [{ pricingSn: '43', discountAmount: 1000, rewardEmail: 0, offPercentage: 0 }],
  },
];

export const mockApplyCoupon = req => {
  const code = req.body.code;
  return code === 'active' ? activeCoupon : { _status: 400, code: 4005001 };
};
