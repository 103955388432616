import curry from 'lodash-es/curry';

const ifElse = <T>(
  condition: (value: T) => boolean,
  onTrue: (value: T) => any,
  onFalse: (value: T) => any,
  value: T
) => (condition(value) ? onTrue(value) : onFalse(value));

export default curry(ifElse);
