import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '../../../../../apps/newsleopard-v2-frontend/src/environments/environment';
@Directive({
  selector: '[nlDeveloping]',
})
export class DevelopingDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (environment.production) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }
}
