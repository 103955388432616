import isEqual from 'lodash-es/isEqual';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

interface ObservableInputProp {
  subject: Subject<any>;
  observable: Observable<any>;
}

const cachedRefs = new WeakMap<Object, Map<string, ObservableInputProp>>();

const getInstanceRefs = (instance: Object) => {
  if (cachedRefs.has(instance)) {
    return cachedRefs.get(instance);
  }

  const observableInputRef = new Map<string, ObservableInputProp>();
  cachedRefs.set(instance, observableInputRef);
  return observableInputRef;
};

const getObservableInput = (instance: Object, property: string, defaultValue: any) => {
  const observableInputRef = getInstanceRefs(instance);
  if (observableInputRef?.has(property)) {
    return observableInputRef?.get(property);
  }

  const subject = new BehaviorSubject(defaultValue);
  const observableInputProps = {
    subject,
    observable: subject.asObservable().pipe(shareReplay(1), distinctUntilChanged(isEqual)),
  };
  observableInputRef?.set(property, observableInputProps);
  return observableInputProps;
};

export function ObservableInput(defaultValue?: any) {
  return (target: any, property: string) => {
    delete target[property];

    Object.defineProperty(target, property, {
      set(value) {
        setTimeout(() => getObservableInput(this, property, defaultValue)?.subject.next(value));
      },
      get() {
        return getObservableInput(this, property, defaultValue)?.observable;
      },
    });
  };
}

/**
 * Make an input observable
 *
 * ** IMPORTANT **: you only use ther inner function to get the reactive observable
 *                  only once to prevent unexpected duplicate ReplaySubject instance
 *
 * TODO: design a cache mechanism with graceful cleanup on component destroy
 */
export const fromInput = <T>(target: T) => {
  return <K extends keyof T>(name: K, defaultValue?: T[K]): Observable<T[K]> => {
    let current: T[K];
    const subject = new ReplaySubject<T[K]>(1);

    if (target[name] !== undefined) {
      subject.next(target[name] as any);
      current = target[name];
    } else if (defaultValue !== undefined) {
      subject.next(defaultValue);
      current = defaultValue;
    }

    Object.defineProperty(target, name, {
      set(value: T[K]) {
        subject.next(value);
        current = value;
      },
      get() {
        return current;
      },
    });

    return subject.asObservable();
  };
};
