import { always, cond, startsWith, T } from 'lodash/fp';
import { mfaEnabled } from './mfa';

const validToken =
  'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0MDI4ZWQ5YjY4N2RlMWMzMDE2ODdkZTVlNjJlMDAwMCIsInJvbGVzIjpbIlJPTEVfTkxfVVNFUiIsIlJPTEVfVVNFUiIsIlJPTEVfU05fVVNFUiJdLCJpc3MiOiJuZXdzbGVvcGFyZCIsInJlbSI6dHJ1ZSwibGNhIjoxNzE4MTc2MzQxMDAwLCJleHAiOjE3MTk0NzIzNzJ9.fLpB_dEN2k52V1BbVG5kyxSqRqQJkn8fY9trvzd3rfaWCawurHm-MZBn83cgM9cjEQAoyh43W377wAumLlL_bNHGxb3FO3Eyg6l2rXB1bxt1z60xd8R0J5_OQcdY6WqWVRw9fwFOHZ0YtkN6AjtbHlZIowG5iCG5CPC5lEIQwWmZ8hSlImGtyPKiU2yVCaa-SnNfztQBUlPChvxeJvo_ql4HOzN3Yb4wUwvKoDrjeOD1znaIKmgKv-4bHjjhCbKaSkll7yDq664irxqMvRDFY1GSr6EWOQkOsWSClQbhswU0BDVe1wZrA4rx2mm-bTRyx-z93drH9LMQ9qBnp-51rQ';
export const login = ({ body }) => {
  const { username, code } = body;
  if (code === '123') {
    return { _status: 403, code: 40312 };
  }

  return cond([
    [
      startsWith('missing'),
      always({
        _status: 403,
        code: 40311,
        recipients: ['cle****@newsleopard.tw', 'r**@newsleopard.com'],
      }),
    ],
    [startsWith('inactive'), always({ _status: 400, code: 40110 })],
    // @ts-ignore
    [T, always({ headers: { Authorization: `Brearer ${validToken}` } })],
    // @ts-ignore
  ])(username);
};

const twmAccount = {
  sn: '4028e15969055595016909eb22110000',
  account: 'goodgu@newsleopard.tw',
  status: 'NORMAL',
  roles: ['ROLE_NL_USER', 'ROLE_USER', 'ROLE_CS_ADMIN', 'ROLE_SN_USER'],
  type: '9',
  hasCustom: false,
  expiredDate: '2022-03-22T00:00:00Z',
};

export const account = ({ body }) => {
  return {
    timeOffset: '+0800',
    sn: '40283b5c641b37c201641ca5ede60026',
    type: '9',
    account: 'clement@newsleopard.com',
    expiredDate: '2018-07-20T10:04:17Z',
    roles: ['ROLE_NL_USER', 'ROLE_USER', 'ROLE_CS_ADMIN', 'ROLE_SN_USER'],
    isNewsleopardActive: true,
    isSmsActive: true,
    remaining: 0,
    smsRemaining: 0,
    emailQuotaThreshold: 1000,
    smsQuotaThreshold: 0,
    domainAuthenticationStatus: 1,
    mfaEnabled: mfaEnabled(),
  };
  return {
    sn: '40283b5c641b37c201641ca5ede60026',
    account: 'clement@newsleopard.tw',
    type: '1',
    hasCustom: false,
    expiredDate: '2018-07-20T10:04:17Z',
  };
  return twmAccount;
};

export const resend = ({ body }) => {
  const { email } = body;
  return cond([
    [startsWith('bad'), always({ _status: 500 })],
    [T, always({})],
  ]);
};
