import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { nonEmpty } from '@nl/utils';
import { includes } from 'lodash-es';
import { eq, propEq } from 'lodash/fp';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DomainAuthenticationStatus, UserType, UserTypes } from './account.model';
import { AccountState, AccountStore } from './account.store';

@Injectable({ providedIn: 'root' })
export class AccountQuery extends Query<AccountState> {
  userSn$ = this.select(state => state.sn).pipe(filter(nonEmpty));
  account$ = this.select(state => state.account).pipe(filter(nonEmpty));
  type$ = this.select(state => state.type);
  typeDesc$ = this.type$.pipe(map(type => UserTypes[type]));
  expiredAt$ = this.select(state => state.expiredDate);
  remaining$ = this.select(state => state.remaining);
  smsRemaining$ = this.select(state => state.smsRemaining);
  emailQuotaThreshold$ = this.select(state => state.emailQuotaThreshold);
  smsQuotaThreshold$ = this.select(state => state.smsQuotaThreshold);
  isFreeTrial$ = this.type$.pipe(map(type => this.isFreeTrial(type)));
  isPaidUser$ = this.type$.pipe(map(type => this.isPaidUser(type)));
  isTwmUser$ = this.type$.pipe(map(eq(UserType.TWM_PROJECT)));
  isSmsActive$ = this.select(state => state.isSmsActive);
  domainAuthenticationStatus$ = this.select(state => state.domainAuthenticationStatus);
  domainAuthenticated$ = this.domainAuthenticationStatus$.pipe(
    map(status => status === DomainAuthenticationStatus.AUTHENTICATED)
  );
  mfaEnabled$ = this.select(state => state.mfaEnabled);

  hasBonusQuotaUser$ = this.type$.pipe(
    map(type =>
      includes([UserType.PAID_ACCOUNT_OLD, UserType.APP_WORKS_PROJECT, UserType.NPO], type)
    )
  );
  nonProjectUser$ = this.type$.pipe(
    map(
      type =>
        !includes(
          [UserType.APP_WORKS_PROJECT, UserType.PROJECT, UserType.PROJECT_OLD, UserType.NPO],
          type
        )
    )
  );
  hadTrialEnded$ = this.select(state => state.roles).pipe(
    map(roles => includes(roles, 'ROLE_EXPIRED'))
  );
  waitCsConfirm$ = this.select(state =>
    state.isNewsleopardActive === undefined ? false : !state.isNewsleopardActive
  );

  isNewsleopardActive$ = this.select(propEq('isNewsleopardActive', true));
  endOfTrialComming$ = combineLatest([
    this.isFreeTrial$,
    this.hadTrialEnded$,
    this.waitCsConfirm$,
  ]).pipe(
    map(
      ([isFreeTrial, hadTrialEnded, waitCsConfirm]) =>
        isFreeTrial && !hadTrialEnded && !waitCsConfirm
    )
  );
  timeOffset$ = this.select(state => state.timeOffset);
  emailQuotaInsufficient$ = combineLatest([this.remaining$, this.emailQuotaThreshold$]).pipe(
    map(([remaining, emailQuotaThreshold]) => remaining < emailQuotaThreshold)
  );
  smsQuotaInsufficient$ = combineLatest([this.smsRemaining$, this.smsQuotaThreshold$]).pipe(
    map(([remaining, smsQuotaThreshold]) => remaining < smsQuotaThreshold)
  );

  isInsufficient$ = combineLatest([this.smsQuotaInsufficient$, this.emailQuotaInsufficient$]).pipe(
    map(([sms, email]) => sms || email)
  );

  constructor(protected store: AccountStore) {
    super(store);
  }

  private isFreeTrial(type: UserType) {
    return includes([UserType.FREE_ACCOUNT, UserType.FREE_ACCOUNT_OLD], type);
  }

  private isPaidUser(type: UserType) {
    return includes(
      [
        UserType.PAID_ACCOUNT,
        UserType.PAID_ACCOUNT_OLD,
        UserType.PAID_ACCOUNT_OLD_EXT,
        UserType.PROJECT,
        UserType.PROJECT_OLD,
        UserType.APP_WORKS_PROJECT,
        UserType.NPO,
      ],
      type
    );
  }

  getAccount() {
    const state = this.getValue();
    return { isFreeTrial: this.isFreeTrial(state.type), account: state.account };
  }

  hadActivateNewsleopardProduct() {
    const { roles } = this.getValue();
    return includes(roles, 'ROLE_NL_USER');
  }

  hadActivateSurenotifyProduct() {
    const { roles } = this.getValue();
    return includes(roles, 'ROLE_SN_USER');
  }

  get sn() {
    return this.getValue().sn;
  }
}
