import flow from 'lodash-es/flow';
import isNaN from 'lodash-es/isNaN';
import negate from 'lodash-es/negate';
import toNumber from 'lodash-es/toNumber';

export default negate(
  flow(
    toNumber,
    isNaN
  )
);
