import every from 'lodash-es/every';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isDate from 'lodash-es/isDate';
import isObjectLike from 'lodash-es/isObjectLike';
import isString from 'lodash-es/isString';
import { NestedPath } from '../../types';
import isBlank from '../fp/is-blank';
import nonEmpty from '../fp/non-empty';
import nonNil from '../fp/non-nil';

export const nonBlankOnRequiredProps = <T extends object>(paths: NestedPath<T>[], config: T) => {
  const hasValueOfRequiredProps = (path: NestedPath<T>[]) => {
    const value = get(config, path);
    return isArray(value) || isObjectLike(value)
      ? nonEmpty(value) || isDate(value)
      : isString(value)
      ? !isBlank(value)
      : nonNil(value);
  };

  return every(paths, hasValueOfRequiredProps);
};

export default nonBlankOnRequiredProps;
