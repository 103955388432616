<button
  *ngIf="!isAnchor && !isComingSoon"
  [type]="buttonType"
  [ngClass]="styleClass"
  [disabled]="notAllowed"
  [attr.data-id]="id"
>
  <span class="new-tag" *ngIf="isNew"></span>
  <span *ngIf="sending" class="spinner">
    <i class="fa-solid fa-spinner"></i>
  </span>
  <ng-container *ngIf="!sending; else loading">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </ng-container>
</button>
<ng-template #loading>{{ loadingText }}</ng-template>
<a *ngIf="isAnchor" [href]="href" [ngClass]="styleClass" target="_blank" rel="noopener noreferrer">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>
<div *ngIf="isComingSoon" [ngClass]="styleClass" pTooltip="製作中..." tooltipPosition="top">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
