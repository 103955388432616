export default {
  AUTH_PREFIX: 'newsleopard',
  AUTH_TYPE: 'Brearer',
  // FB_APP_ID: '208306495941067',
  FB_APP_ID: '1513689912179525',
  FB_APP_VERSION: 'v2.10',
  hmr: false,
  dev: false,
  timeoutThreshold: 10000,
  SUBSCRIBE_FORM_LIMIT: 10,
};
