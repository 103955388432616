import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { ifElse, isNumberLike } from '@nl/utils';
import identity from 'lodash-es/identity';

const withDefaultPixelUnit = ifElse(
  isNumberLike,
  (value: string | number) => `${value}px`,
  identity
);

@Directive({
  selector: '[nlSpace]',
})
export class SpaceDirective {
  constructor(private render: Renderer2, private ref: ElementRef) {}

  @Input()
  set m(value: string) {
    this._updateStyle('margin', value);
  }
  @Input()
  set mx(value: string) {
    this.mr = value;
    this.ml = value;
  }
  @Input()
  set my(value: string) {
    this.mt = value;
    this.mb = value;
  }
  @Input()
  set mt(value: string) {
    this._updateStyle('margin-top', value);
  }
  @Input()
  set mr(value: string) {
    this._updateStyle('margin-right', value);
  }
  @Input()
  set mb(value: string) {
    this._updateStyle('margin-bottom', value);
  }
  @Input()
  set ml(value: string) {
    this._updateStyle('margin-left', value);
  }

  @Input()
  set p(value: string) {
    this._updateStyle('padding', value);
  }
  @Input()
  set px(value: string) {
    this.pr = value;
    this.pl = value;
  }
  @Input()
  set py(value: string) {
    this.pt = value;
    this.pb = value;
  }
  @Input()
  set pt(value: string) {
    this._updateStyle('padding-top', value);
  }
  @Input()
  set pr(value: string) {
    this._updateStyle('padding-right', value);
  }
  @Input()
  set pb(value: string) {
    this._updateStyle('padding-bottom', value);
  }
  @Input()
  set pl(value: string) {
    this._updateStyle('padding-left', value);
  }
  @Input()
  set h(value: string) {
    this._updateStyle('height', value);
  }

  _updateStyle(key: string, value: string) {
    this.render.setStyle(this.ref.nativeElement, key, withDefaultPixelUnit(value));
  }
}
