import { curry, filter, flow, fromPairs, get, toPairs } from 'lodash/fp';
import nonEmpty from './non-empty';
import when from './when';

const FIRST = 0;
const VALUE = 1;

export const filterWithKeysPairs = (prediction: (key: any) => boolean) =>
  flow([toPairs, filter(([key, _]) => prediction(key))]);
export const filterWithKeys = flow([filterWithKeysPairs, fromPairs]);
export const firstWithKey = curry(<T>(prediction: (key: any) => boolean, source: T) =>
  flow([filterWithKeysPairs(prediction), when(nonEmpty, get([FIRST, VALUE]))])(source)
);
