import { PayStatus, TwmOrderAccounting } from '../src/app/module/order/twm-order.model';
import { startOfMonth, subMonths } from 'date-fns';

const thisMonth = startOfMonth(new Date());

export const twmOrders: TwmOrderAccounting[] = [
  {
    sn: '1',
    orderNo: '20180425113055',
    status: PayStatus.FAILURE,
    chargePeriod: '2020/12/01-2020/12/31',
    totalSent: 89039,
    totalCost: 12808,
    monthlyFee: 5000,
    quota: 50000,
    overageFee: 7808,
    overageUnitPrice: 0.8,
  },
  {
    sn: '2',
    orderNo: '20180425113056',
    status: PayStatus.COMPLETE,
    chargePeriod: '2020/11/01-2020/11/30',
    totalSent: 48495,
    totalCost: 12808,
    monthlyFee: 5000,
    quota: 50000,
    overageFee: 7808,
    overageUnitPrice: 0.8,
    payDate: subMonths(thisMonth, 1).toISOString(),
  },
  {
    sn: '3',
    orderNo: '20180425113057',
    status: PayStatus.COMPLETE,
    chargePeriod: '2020/10/01-2020/10/31',
    totalSent: 89078,
    totalCost: 12808,
    monthlyFee: 5000,
    quota: 50000,
    overageFee: 7808,
    overageUnitPrice: 0.8,
    payDate: subMonths(thisMonth, 2).toISOString(),
  },
  {
    sn: '4',
    orderNo: '20180425113058',
    status: PayStatus.COMPLETE,
    chargePeriod: '2020/09/01-2020/09/30',
    totalSent: 83039,
    totalCost: 12808,
    monthlyFee: 5000,
    quota: 50000,
    overageFee: 7808,
    overageUnitPrice: 0.8,
    payDate: subMonths(thisMonth, 3).toISOString(),
  },
  {
    sn: '5',
    orderNo: '20180425113059',
    status: PayStatus.COMPLETE,
    chargePeriod: '2020/08/01-2020/08/31',
    totalSent: 90039,
    totalCost: 12808,
    monthlyFee: 5000,
    quota: 50000,
    overageFee: 7808,
    overageUnitPrice: 0.8,
    payDate: subMonths(thisMonth, 4).toISOString(),
  },
];
