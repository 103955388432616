export const mockShopifyCampaignDetail = () => ({
  sn: '402880a8746a787d0174ba0e4d53110e',
  form: {
    name: '2020鈑金雷射展',
    source: 2,
    selectedLists: [
      {
        sn: '40288030747261350174ba05c1561576',
        name: '已下單',
        shopName: '朵朵衣著',
      },

      {
        sn: '40288030747261350174ba05c1561575',
        name: '已下單2',
        shopName: '朵朵衣著',
      },
    ],
    excludeLists: [
      {
        sn: '40288030747261350174ba05c1561576',
        name: '未下單',
        shopName: '朵朵衣著',
      },
      {
        sn: '40288030747261350174ba05c1561576',
        name: '2020雷射鈑金2',
        type: 'FIXED',
        status: 'GENERAL',
        subscribedCnt: 80,
        excludeCnt: 0,
        openedRate: 50.0,
        clickedRate: 0.0,
        createDate: '2020-09-23T08:12:53Z',
        all: false,
      },
    ],
  },
  content: {
    testingOn: 1,
    sn: [83485],
    subject: ['感謝您蒞臨參觀2020雷射鈑金展', '測試唷'],
    fromName: ['新代科技'],
    fromAddress: ['syntecsales@gmail.com'],
    htmlContent: [
      '<!doctype html>\n<html>\n<head>\n<meta charset="utf-8">\n<title>無標題文件</title>\n</head>\n\n<body>\n<p><img src="https://syntecweb.oss-cn-hangzhou.aliyuncs.com/EDM/2020%E6%9D%BF%E9%87%91%E5%B1%95%E6%84%9F%E8%AC%9D%E5%87%BD.png" alt="" usemap="#Map" width="900" height="auto" /> <map name="Map"> \n<area coords="720,716,27" shape="circle" href="https://www.syntecclub.com/catalog.aspx" target="_blank" />\n \n<area coords="777,715,26" shape="circle" href="https://www.facebook.com/SyntecLaser/" target="_blank" />\n \n<area coords="839,714,27" shape="circle" href="https://www.youtube.com/channel/UCM8OB-UHq8yQwrn_q7rm-Ew?view_as=subscriber" target="_blank" />\n \n<area coords="749,777,26" shape="circle" href="https://www.syntecclub.com/default.aspx" target="_blank" />\n \n<area coords="808,777,25" shape="circle" href="https://line.me/R/ti/p/b_71LOKwk4" target="_blank" />\n \n<area coords="1,1,901,656" shape="rect" href="https://www.syntecclub.com/default.aspx" target="_blank" />\n </map></p>\n</body>\n</html>',
    ],
    htmlDescription: [''],
    htmlUpdateOn: ['2020-09-24T07:18:16Z'],
    footerLang: 1,
    footerMsg: '你曾經訂閱這封電子報，如果不想再收到可以選擇 ${UNSUBSCRIBE}',
    testing: { proportion: 0, time: 1, unit: 0, valid: true },
    onTestingMode: false,
  },
  config: {
    schedule: { type: 0, timezone: 21, scheduleDate: '2020-09-24T07:23:50Z' },
    ga: { enable: false, utmMedium: 'email', utmSource: 'newsletter' },
    replyTo: { enable: false },
  },
  status: 'COMPLETE',
});

export const mockCampaignMetrics = () => ({
  source: 2,
  list: [
    {
      sn: '402880f27946cf45017b3dd92a655a47',
      name: '已購買者',
      shopName: '朵朵衣著',
    },
    {
      sn: '402880f27946cf45017b3dd92a655a47',
      name: '潜在購買者',
      shopName: '朵依衣著',
    },
  ],
  sentAt: '2021-08-13T04:57:58Z',
  inspectionAmount: 0,
  recipientCnt: 1,
  delivered: 1,
  opened: 1,
  clicked: 0,
  bounced: 0,
  complained: 0,
  unsubscribed: 0,
  testing: { type: 'NONE', proportion: 0 },
  unitPrice: 0.036,
  gaMetrics: { gaECommerceFlag: 'N', transactions: 0, transactionRevenue: 0 },
});
