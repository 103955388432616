import { BreakPoint, BREAKPOINT } from '@angular/flex-layout';

export const CUSTOM_BREAKPOINTS: BreakPoint[] = [
  {
    alias: 'sm',
    mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
  },
  {
    alias: 'lt-md',
    mediaQuery: '(max-width: 1023px)',
  },
  {
    alias: 'md',
    mediaQuery: '(min-width: 1024px) and (max-width: 1279px)',
  },
  {
    alias: 'gt-md',
    mediaQuery: '(min-width: 1024px)',
  },
];
export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true,
};
