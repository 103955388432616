import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@nl/email/app/shared/service/auth/auth.service';
import { REDIRECT_ACTION, REDIRECT_PAGE } from '@nl/utils';
import { Observable } from 'rxjs';
import { AccountQuery } from '../layout';

@Injectable()
export class GuestGuard {
  constructor(
    private router: Router,
    private auth: AuthService,
    private account: AccountQuery,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(route.queryParams[REDIRECT_PAGE], route.queryParams[REDIRECT_ACTION]);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  checkLogin(from: string, action: string): boolean {
    if (action === 'logout') {
      this.auth.logout();
      return true;
    }

    if (this.account.getValue().sn) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    return true;
  }
}
