import { Validators } from '@angular/forms';

const datePrefix = 'yyyyMMddhhmmss';
const uuidChar = (len: number) => `[0-9a-f]{${len}}`;
const uuid4 = `${uuidChar(8)}-${uuidChar(4)}-4${uuidChar(3)}-[89ab]${uuidChar(3)}-${uuidChar(12)}`;

export const msgIdValidator = Validators.pattern(
  new RegExp(`^\\d{${datePrefix.length}}-[01]-${uuid4}$`)
);

export const isEventId = (id: string) =>
  new RegExp(`^\\d{${datePrefix.length}}-[01]-${uuid4}$`).test(id);

export default msgIdValidator;
