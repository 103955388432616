import eq from 'lodash-es/eq';
import forEach from 'lodash-es/forEach';
import isArray from 'lodash-es/isArray';
import isFunction from 'lodash-es/isFunction';
import negate from 'lodash-es/negate';
import noop from 'lodash-es/noop';
import { always, propEq } from 'lodash/fp';
import { Observable, of, PartialObserver, Subject } from 'rxjs';
import { catchError, filter, mergeMap, share, switchMap, tap } from 'rxjs/operators';
import ifElse from '../fp/if-else';
import nonNil from '../fp/non-nil';

export const switchMapWithCatch =
  <T, R>(action: (arg: T) => Observable<R>) =>
  (source: Observable<T>) =>
    source.pipe(
      switchMap(value => action(value).pipe(catchError(err => of({ _error: true, ...err }))))
    );

export function actionWithLoading<T = any, R = any>(
  loading$: Subject<boolean> | Subject<boolean>[] = [new Subject()],
  action: (arg: T) => Observable<R>,
  catchError = true
) {
  const loadingSubjects = isArray(loading$) ? loading$ : [loading$];
  const turnLoadingTo = (state: boolean) => () =>
    forEach(loadingSubjects, loading => loading.next(state));
  const doAsyncTask = isFunction(action) ? action : () => action;

  return function (source: Observable<T>): Observable<R> {
    return source.pipe(
      filter(value => eq(0, value) || nonNil(value)),
      tap(turnLoadingTo(true)),
      catchError
        ? switchMapWithCatch(value => doAsyncTask(value))
        : switchMap(value => doAsyncTask(value)),
      tap(turnLoadingTo(false), turnLoadingTo(false))
    );
  };
}

type ObservableProvider<R = any> = (...args: any[]) => Observable<R>;
export const toggleAction = <R>(loading$: Subject<boolean>, action: ObservableProvider<R>) =>
  of(true).pipe(actionWithLoading(loading$, action), share());

const apiError = propEq('_error', true);

export const onApiSuccess = filter(negate(apiError));
export const onEvent = <T>(observer: PartialObserver<T>): PartialObserver<T> => ({
  next: ifElse(apiError, observer.error || noop, observer.next || noop),
  error: observer.error,
  complete: observer.complete,
});

export const mapToOnSuccess = <S, T>(default$: Observable<T>) => {
  return function (source: Observable<S>) {
    return source.pipe(mergeMap(ifElse(propEq('_error', true), of, always(default$))));
  };
};
