import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { RoleType } from '@nl/email/app/shared/model/auth.model';
import { DomainAuthenticationStatus, UserStatus, UserType } from './account.model';

export interface AccountState {
  sn?: string;
  type?: UserType;
  account?: string;
  status?: UserStatus;
  expiredDate?: string;
  roles?: RoleType[];
  timeOffset: string;
  isNewsleopardActive?: boolean;
  isSmsActive?: boolean;
  remaining?: number;
  emailQuotaThreshold?: number;
  smsQuotaThreshold?: number;
  smsRemaining?: number;
  domainAuthenticationStatus?: DomainAuthenticationStatus;
  mfaEnabled?: boolean;
}

export function createInitialState(): AccountState {
  return {
    timeOffset: '+0800',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'account', resettable: true })
export class AccountStore extends Store<AccountState> {
  constructor() {
    super(createInitialState());
  }
}
