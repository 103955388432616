import { Pipe, PipeTransform, InjectionToken, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

export interface DateConfig {
  offset: string;
}

export const DATE_CONFIG = new InjectionToken<DateConfig>('date-text-config');

@Pipe({ name: 'dateText' })
export class DateTextPipe implements PipeTransform {
  constructor(@Inject(DATE_CONFIG) private config: DateConfig, private datePipe: DatePipe) {}

  transform(value: any, format = 'yyyy/MM/dd HH:mm', placeholder = '-'): any {
    return this.datePipe.transform(value, format, this.config.offset) || placeholder;
  }
}
