import { MockDataInterceptor } from './mock-data.interceptor';
import { MockData, MockInterceptorConfig, MOCK_CONFIG, MOCK_PROVIDER } from './mock-data.model';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

export function mockInterceptorFactory(mockProvider: MockData, config?: MockInterceptorConfig) {
  return new MockDataInterceptor(mockProvider, config);
}

@NgModule()
export class MockDataModule {
  static forRoot(mockProvider: MockData, config?: MockInterceptorConfig): ModuleWithProviders<MockDataModule> {
    return {
      ngModule: MockDataModule,
      providers: [
        { provide: MOCK_PROVIDER, useValue: mockProvider },
        { provide: MOCK_CONFIG, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          useFactory: mockInterceptorFactory,
          deps: [MOCK_PROVIDER, MOCK_CONFIG],
          multi: true,
        },
      ],
    };
  }
}
