import { flow, replace } from 'lodash/fp';

export const replaceNewline = (content: string) =>
  flow([replace(/\\r?(:\n|\\n)/g, '\n'), replace(/\r|\\r|\n|\\n/g, '\n')])(content.trim());

export const variableParams = {
  prefix: '${',
  suffix: '}',
  paramPattern: /\${([^\$}]+)*}/g,
  linkPattern:
    / (http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)(?=\. | |\n|\r\n|\r|$)/g,
};

export const styledLinkText = (content: string) =>
  replace(variableParams.linkPattern, `<a href="$&" target="_blank">$&</a>`)(content);
