import { BillingPlan } from './../src/app/module/user-plan/user-plan.model';
export const billingPlans: BillingPlan[] = [
  {
    sn: '402823eb6b2c7378016b3f54000f0000',
    emailQuota: 2500,
    emailPrice: 600,
    emailUnitPrice: 0.2,
    name: 'LPA-S00001',
    twmType: '入門型',
  },
  {
    sn: '402823eb6b2c7378016b3f54000f0002',
    emailQuota: 10000,
    emailPrice: 1500,
    emailUnitPrice: 0.12,
    name: 'LPA-S00002',
    twmType: '基礎型',
  },
];

export const userPlan = {
  totalSent: 2500,
  status: 'SUBSCRIBED',

  name: 'LPA-S00001',
  checkoutType: 0,
  monthlyFee: 500,
  quota: 2500,
  overageUnitPrice: 0.2,

  nextName: '',
  nextCheckoutType: 0,
  nextMonthlyFee: 1500,
  nextQuota: 10000,
  nextOverageUnitPrice: 0.12,
};
