import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { eq } from 'lodash/fp';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

export function formStatusChanges(form: AbstractControl): Observable<any> {
  return form.statusChanges.pipe(startWith(form.status));
}

export function formInvalidChanges(form: AbstractControl): Observable<boolean> {
  return formStatusChanges(form).pipe(distinctUntilChanged(), map(eq('INVALID')));
}

export function formInvalidChangesDelays(form: AbstractControl, time = 0): Observable<boolean> {
  const invalid = new BehaviorSubject(form.invalid);
  formInvalidChanges(form)
    .pipe(untilDestroyed(form))
    .subscribe(isInvalid => invalid.next(isInvalid));
  setTimeout(() => invalid.next(form.invalid), time);
  return invalid.asObservable();
}
