import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { cond, constant, flow, isEmpty, omit, stubTrue } from 'lodash/fp';

const isEmptyControl = (field: string) => flow(group => group.get(field), isEmpty);
const isPasswordsMatches =
  (passwordControl: string, confirmControl: string) => (group: FormGroup) => {
    const password = group.get(passwordControl)!.value;
    const confirm = group.get(confirmControl)!.value;
    return password === confirm;
  };
const stubNull = constant(null);

export default function passwordConfirmValidator({
  password = 'password',
  confirm = 'confirm',
}): ValidatorFn {
  return function validate(control: AbstractControl): { [key: string]: any } | null {
    const group = control as FormGroup;

    const violation = cond([
      [isEmptyControl(password), stubNull],
      [isEmptyControl(confirm), stubNull],
      [isPasswordsMatches(password, confirm), stubNull],
      [stubTrue, constant({ passwordNotMatch: true })],
    ])(group);

    const confirmControl = group.get(confirm);
    const rawErrors = omit('passwordNotMatch', confirmControl?.errors);
    const errors = { ...rawErrors, ...violation };
    const nextErrors = isEmpty(errors) ? null : errors;
    confirmControl?.setErrors(nextErrors);
    return violation;
  };
}
