import { AbstractControl, FormControl } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import identity from 'lodash-es/identity';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, tap, withLatestFrom } from 'rxjs/operators';
import when from './../fp/when';

interface Boundary {
  context: any;
  min?: number;
  max: number;
  onchange?: (num: number) => void;
  controlToView?: (controlValue: any) => number;
  viewToControl?: (viewValue: number) => any;
  model?: Subject<any>;
}

export const boundedControl = (
  control: AbstractControl,
  {
    min = 0,
    max,
    context,
    onchange,
    controlToView = identity,
    viewToControl = identity,
    model = new Subject(),
  }: Boundary
): AbstractControl => {
  const viewControl = new FormControl(controlToView(control.value), {
    updateOn: control.updateOn,
  });
  const change = onchange || control.setValue.bind(control);
  const outOfBoundary = (val: number) => val > max || val < min;
  const clamp = (val: number) => (val > max ? max : min);

  viewControl.valueChanges
    .pipe(
      map(when(outOfBoundary, clamp)),
      tap((num: any) => viewControl.setValue(num, { emitEvent: false })),
      withLatestFrom(model.asObservable()),
      filter(([view, modelValue]) => view !== modelValue),
      untilDestroyed(context)
    )
    .subscribe(([view]) => change(viewToControl(view)));

  control.valueChanges
    .pipe(startWith(controlToView(control.value)), untilDestroyed(context))
    .subscribe(num => {
      const viewValue = controlToView(num);
      if (viewControl.value !== viewValue) {
        viewControl.setValue(viewValue);
      }
      model.next(viewValue);
    });

  control.statusChanges
    .pipe(
      startWith(control.status),
      distinctUntilChanged((prev, curr) => !(prev === 'DISABLED' || curr === 'DISABLED')),
      untilDestroyed(context)
    )
    .subscribe((status: string) => {
      if (status === 'DISABLED') {
        viewControl.disable();
      } else {
        viewControl.enable();
      }
    });

  return viewControl;
};
