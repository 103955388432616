import { Inject, InjectionToken, Injector, NgModule, Type } from '@angular/core';
import isPlainObject from 'lodash-es/isPlainObject';
import { isObservable } from 'rxjs';
import { EFFECT } from './create-effect';

const EFFECT_PROVIDERS = new InjectionToken('Effect Providers');

@NgModule({})
export class EffectsModule {
  constructor(@Inject(EFFECT_PROVIDERS) providers: Type<any>[], injector: Injector) {
    for (let provider of providers) {
      const currProvider = injector.get(provider);

      for (const effectFactory of Object.values(currProvider)) {
        if (effectFactory[EFFECT] && !isPlainObject(effectFactory)) {
          const factory = (effectFactory as any)();

          if (isObservable(factory)) {
            factory.subscribe();
          }
        }
      }
    }
  }

  static register(providers: Type<any>[]) {
    return {
      ngModule: EffectsModule,
      providers: [
        {
          provide: EFFECT_PROVIDERS,
          useValue: providers,
        },
      ],
    };
  }
}
