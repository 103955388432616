import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isEmpty } from 'lodash-es';
// tslint:disable-next-line:max-line-length
const EMAIL_REG =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const CHINESE_REG = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;

export const isWellEmailFormat = (address: string) => EMAIL_REG.test(address);

export const noContainChinese = (address: string) => !CHINESE_REG.test(address);

export default function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control?.value;
    const isWellFormat = isWellEmailFormat(value);
    return isEmpty(value) || isWellFormat ? null : { email: { value } };
  };
}
