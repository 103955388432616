import identity from 'lodash-es/identity';
import isNil from 'lodash-es/isNil';
import isPlainObject from 'lodash-es/isPlainObject';
import overEvery from 'lodash-es/overEvery';
import { always, cond, every, filter, flow, isArray, map, mapValues, omitBy } from 'lodash/fp';
import nonNil from './non-nil';

export const sanitizeNulls = (source: object): Record<string, any> =>
  omitBy(
    isNil,
    mapValues(
      cond([
        [overEvery(isArray, every(isPlainObject)), flow([filter(nonNil), map(sanitizeNulls)])],
        [isPlainObject, sanitizeNulls],
        [always(true), identity],
      ]),
      source
    )
  );
