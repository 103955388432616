// tslint:disable:no-magic-numbers

import { flow, includes, indexOf, toLower } from 'lodash/fp';

const increment = (n: number): number => n + 1;
const substring = (input: string) => (start: number, end: number) => input.substring(start, end);
const getDomainIndex = flow([indexOf('@'), increment]);
const isYahooDomain = address =>
  flow([getDomainIndex, substring(address), toLower, includes('yahoo')])(address);

export default (error: any, statusCode: number, body: any) => {
  switch (error.code) {
    case 40001:
      return { invalidFields: error.invalidFields };
    case 40004:
      return { badDomain: true, isYahoo: isYahooDomain(body.email || body.address) };
    case 40007:
      return { noDataFound: true };
    case 400010:
    case 40011:
      return { unauthenticatedDomain: true };
    case 40012:
      return { overBalance: true };
    case 40013:
      return { noRecipients: true };
    case 40014:
      return { badImageFormat: true };
    case 40017:
      return { insufficientRecipients: true };
    case 40019:
      return { badScheduleDate: true };
    case 40021:
      return { bot: true };
    case 40025:
      return { twmNotSupportSMS: true };
    case 40026:
      return { unverifiedSms: true };
    case 40102:
      return { badCredentials: true };
    case 40103:
      return { noSuchUser: true };
    case 40104:
      return { lockedUser: true };
    case 40110:
      return { inactiveUser: true };
    case 40111:
      return { bannedUser: true };
    case 40301:
      return { noUserFound: true };
    case 40302:
      return { incorrectPassword: true };
    case 40303:
      return { badStateOnResetPassword: true };
    case 40305:
      return { isFacebookUser: true };
    case 40306:
      return { activatedAccount: true };
    case 40307:
      return { duplicateAddress: true };
    case 40308:
      return { duplicateWithFb: true };
    case 40309:
      return { reportProcessing: true };
    case 40310:
      return { unsubscribedUser: true };
    case 4005001:
      return { couponNotExist: true };
    case 4005002:
      return { couponIsExpired: true };
    case 4005003:
      return { couponIsUsed: true };
    case 4005004:
      return { couponInvalid: true };
    case 50010:
      return { isCampaignSending: true };
    case 400005:
      return { automationIdDuplicate: true };
    case 400006:
      return { automationBandUser: true };
    case 400007:
      return { automationUnsubscribedUser: true };
    case 400008:
      return { automationOverBalance: true };
    case 400009:
      return { automationOverLimit: true };
    case 400003:
      return { dateOverDue: true };
    case 40027:
      return { duplicatePageId: true };
    case 40029:
      return { duplicateBranding: true };
    case 40035:
      return { landingPageAmountLimit: true };
    case 40037:
      return { brandingAmountLimit: true };
    case 40311:
      return { missingOtp: true };
    case 40312:
      return { invalidOtp: true };
    case 40313:
      return { duplicateEntity: true };
  }
};
