import { MatDialogConfig } from '@angular/material/dialog';

export const fullScreen: MatDialogConfig = {
  width: '100%',
  maxWidth: '100%',
  minWidth: '100%',
  height: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  panelClass: 'fullscreen-dialog',
};

export const almostFullScreen: MatDialogConfig = {
  ...fullScreen,
  panelClass: 'huge-dialog',
};

export const normalScreen: MatDialogConfig = {
  panelClass: 'huge-dialog',
};
