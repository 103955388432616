import { AccountInfo } from '../src/app/shared/model/account.model';
export const mockPaymentInfo = () => ({
  sn: '40281f296797ce360167a0d669cd000c',
  contactName: '康家睿',
  contactMailAddress: 'kangjaray@gmail.com',
  contactTel: '0978998052',
  zip: '110',
  city: '台北市',
  district: '信義區',
  street: '基隆路一段178號7F',
  corpNo: '52351786',
  corpName: '快豹科技有限公司',
});

export const checkActivate = ({ pwdHash }) => {
  if (pwdHash === 'bad') {
    return { _status: 400, code: '40006' };
  }

  const info: AccountInfo = {
    sn: '4028dc1766621f3d016677094dc4002c',
    type: '8',
    hasCustom: false,
    account: 'clement@newsleopard.tw',
    expiredDate: '2019-01-25T04:02:05Z',
  };

  return info;
};

export const activate = ({ body }) => {
  if (body.corpName === 'bad') {
    return { _status: 400, code: '40006' };
  }
  return {};
};

export const currentInfo = {
  account: 'clement@newsleopard.tw',
  name: 'clay',
  contactMailAddress: 'clement@newsleopard.tw',
};
