import { Validators } from '@angular/forms';
import { size } from 'lodash/fp';

import rangeValidator from './validator/range.validator';
export const passwordValidator = rangeValidator({
  name: 'password',
  min: 8,
  max: 16,
  mapping: size,
});

const passwordControl = ({ disabled = false } = {}) => [
  { value: '', disabled },
  Validators.compose([
    Validators.required,
    rangeValidator({
      name: 'password',
      min: 8,
      max: 16,
      mapping: size,
    }),
  ]),
];

export default passwordControl;
