import { WebStorageUtility } from './webstorage.utility';

export class SharedStorageUtility extends WebStorageUtility {
  public override getSettable(value: any): any {
    return value;
  }

  public override getGettable(value: any): any {
    return value;
  }
}
