import { AbstractControl, ValidatorFn } from '@angular/forms';
import { identity, toNumber } from 'lodash-es';

export interface RangeConfig {
  name?: string;
  mapping?: any;
  min: number;
  max: number;
}

export default function rangeValidator({
  name = '',
  mapping = identity,
  min,
  max,
}: RangeConfig): ValidatorFn {
  const violationKey = name ? `${name}Range` : 'range';
  return (control: AbstractControl): { [key: string]: any } | null => {
    const { value } = control;
    if (!value) {
      return null;
    }

    const num = toNumber(mapping(value));
    return num >= min && num <= max ? null : { [violationKey]: { value: num, min, max } };
  };
}
