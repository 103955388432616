import { MfaClient } from '@nl/email/app/module/account/mfa-clients.model';

let _mfaEnabled = true;
export const mfaEnabled = () => {
  return _mfaEnabled;
};
const now = new Date().toISOString();
let dummyClients: MfaClient[] = [
  // {
  //   userSn: 'cc',
  //   recipient: 'clement@newsleopard.com',
  //   isActive: true,
  //   channel: 0,
  //   createDate: now,
  //   updateDate: now,
  // },
  // {
  //   userSn: 'cc',
  //   recipient: 'ray@newsleopard.com',
  //   isActive: false,
  //   channel: 0,
  //   createDate: now,
  //   updateDate: now,
  // },
];

export const mockMfaClients = () => ({
  clients: mfaEnabled() ? dummyClients.filter(({ isActive }) => isActive) : [],
});

export const mockMfaEnable = () => {
  _mfaEnabled = true;
  return { _status: 200 };
};

export const mockMfaDisable = () => {
  _mfaEnabled = false;
  return { _status: 200 };
};

export const mockRegisterMfaClient = req => {
  const { recipient } = req.body;
  const exits = dummyClients.find(c => c.recipient === recipient && c.isActive);
  return exits ? { _status: 403, code: 40313 } : { sn: req.body.recipient };
};

export const mockCodeGetter = () => ({ _status: 204 });

export const mockCodeVerifier = req => {
  const { code, recipient, channel } = req.body;
  const isValid = code === '123123';
  if (isValid) {
    dummyClients = [
      ...dummyClients,
      {
        userSn: 'cc',
        recipient,
        isActive: true,
        channel,
        createDate: now,
        updateDate: now,
      },
    ];
  }
  return isValid ? { _status: 200 } : { _status: 403, code: 40312 };
};
