// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import base from './environment.common';

export const environment = {
  ...base,
  dev: false,
  production: false,
  apiHost: `${$ENV.API_HOST || 'https://api-backend.staging.newsleopard.de'}/api`,
  contactApiHost: `${$ENV.API_HOST || 'https://api-backend.staging.newsleopard.de'}/api`,
  socketHost: $ENV.SOCKET_HOST || 'https://socketio.staging.newsleopard.de',
  s3Domain: $ENV.S3_DOMAIN || 's3.amazonaws.com',
  envName: $ENV.ENV || 'staging',
  hmr: false,
  isDebugMode: true,
  siteDomain: $ENV.SITE_DOMAIN || 'localhost',
  importBucketName: `${$ENV.ENV || 'staging'}-member-upload-v2`,
  recaptchaKey: $ENV.RECAPTCHA_KEY || '6LdaLt8UAAAAAM1PT7Ka55zgbK939fQdaoKMuE17',
  surenotifyConsole: $ENV.SURENOITFY_CONSOLE || 'https://sn.newsleopard.de',
  defaultNotFoundPageUrl: 'https://notfound.newsleopard.de',
  defaultFaviconUrl: `https://nlstatic.newsleopard.de/system-resources/images/favicon-company.png`,
  integration: {
    ga: {
      oauthEndpoint:
        'https://accounts.google.com/o/oauth2/auth?response_type=code&prompt=consent&access_type=offline',
      scope:
        'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/analytics.readonly',
      clientId:
        $ENV.GOOGLE_CLIENT_ID ||
        '130213787535-ev02ibrcjksii4epppnam6n1qc4tuduu.apps.googleusercontent.com',
    },
    shopify: {
      appEndpoint: 'https://partners.shopify.com/2079657/apps/5694847/test',
    },
  },
  isDevMode: true,
  landingPageDomain: '1landing.net',
};
