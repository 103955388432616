export { applyASAP } from './apply-asap';
export { boundedControl } from './comps/bounded-control';
export { catchHttpErrors } from './comps/catch-http-errors';
export { getCurrentRoute } from './comps/get-current-route';
export { almostFullScreen, fullScreen } from './comps/mat-full-screens';
export { default as updatePropsOnNextFrame } from './comps/update-props-next-frame';
export { addCssVariables, removeCssVariables } from './css-variables';
export { flatten, unflatten } from './flatten';
export { cloneFormGroup } from './form/clone-form';
export { default as formValueChanges } from './form/form-changes-with-initial';
export { allFormErrors } from './form/form-errors';
export { ConfigFormHelpers, existingFormHelpers } from './form/form-helpers';
export {
  formInvalidChanges,
  formInvalidChangesDelays,
  formStatusChanges,
} from './form/form-status-change';
export { formSubmit } from './form/form-submit';
export { nonBlankOnRequiredProps } from './form/non-blank-on-required-props';
export { default as passwordControl, passwordValidator } from './form/password-control';
export { default as requiredForms } from './form/required-forms';
export { default as emailValidator } from './form/validator/email.validator';
export { default as msgIdValidator } from './form/validator/msgId.validator';
export { default as noChineseValidator } from './form/validator/no-chinese.validator';
export { default as passwordConfirmControl } from './form/validator/password-confirm.validator';
export { default as phoneValidator } from './form/validator/phone.validator';
export { default as rangeValidator } from './form/validator/range.validator';
export { default as httpsUrlValidator } from './form/validator/https-url.validator';
export { default as some } from './form/validator/some';
export { default as and } from './fp/and';
export { default as call } from './fp/call';
export { default as chainToArgs } from './fp/chain-to-args';
export { default as converge } from './fp/converge';
export { filterWithKeys, firstWithKey } from './fp/filter-with-keys';
export { flattenProps } from './fp/flatten-props';
export { getOptionLabel } from './fp/get-enum-label';
export { hasError } from './fp/has-errors';
export { default as hasValue } from './fp/has-value';
export { default as ifElse } from './fp/if-else';
export { inBlacklist, inWhitelist, notInBlacklist, notInWhitelist } from './fp/includes';
export { insertIf } from './fp/insert-if';
export { default as isBlank } from './fp/is-blank';
export { default as isNumberLike } from './fp/is-number-like';
export { default as map } from './fp/map-with-index';
export { mergeWithArrayOverride } from './fp/merge-with-array-override';
export { default as nonEmpty } from './fp/non-empty';
export { default as nonNil } from './fp/non-nil';
export { default as renameProps } from './fp/rename-props';
export { sanitizeNulls } from './fp/sanitize-nulls';
export { sizeEq, sizeNotEq, sizeOver } from './fp/size-eq';
export { startsWithIgnoreCase } from './fp/starts-with-ignore-case';
export { tap, trace, warn } from './fp/trace';
export { unapply } from './fp/unapply';
export { default as unless } from './fp/unless';
export { default as when } from './fp/when';
export { getFileExt } from './get-file-ext';
export { parseJSON } from './json-parse';
export { ObservableInput, fromInput } from './observable-input';
export { parseLinks } from './parseLinks';
export { isFormDisabled } from './pipe/isFormDisabled';
export {
  actionWithLoading,
  mapToOnSuccess,
  onEvent,
  switchMapWithCatch,
  toggleAction,
} from './pipe/loading';
export { replaceNestedProps } from './replace-nested-props';
export { replaceNewline, styledLinkText } from './sms-content-replcae';
export { syncStatusDisabled } from './sync-status-disabled';
export { asType } from './ts-literal-type';
export { PartialExcept, RecursivePartial } from './ts-partial-except';
export { Lit, tuple } from './ts-tuple';

export const REDIRECT_PAGE = '_cbp';
export const REDIRECT_ACTION = '__act';
