import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';
import { Observable } from 'rxjs';

@Injectable()
export class HarmfulCharsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skip = !!req.headers.get('noFilter');
    if (skip) {
      return next.handle(req.clone({ headers: req.headers.delete('noFilter') }));
    }
    const request = req.clone({
      body: isObject(req.body) ? this.removeFromBody(req.body) : req.body,
      params: this.removeFromParams(req.params),
    });
    return next.handle(request);
  }

  removeFromBody(body) {
    return JSON.parse(JSON.stringify(body).replace(/[\u200b]/g, ''));
  }

  removeInvisibleChar(s: string) {
    return s.replace(/[\u200b]/g, '');
  }

  removeFromParams(params: HttpParams) {
    const acc = {};
    params.keys().forEach(key => {
      acc[key] = isString(params.get(key))
        ? this.removeInvisibleChar(params.get(key))
        : params.get(key);
    }, {});

    return new HttpParams({ fromObject: acc });
  }
}
