import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomBreakPointsProvider } from '@nl/email/app/shared/break-point.module';
import { environment } from '@nl/email/env';
import setup from '@nl/email/mocks';
import { MockDataModule } from '@nl/mocks';
import { insertIf } from '@nl/utils';
import { ExternalLibrariesLoaderService } from './external-libraries-loader.service';
import { FilterNonAsciiModule } from './harmful-chars-interceptor/harmful-chars-interceptor.module';
import { JwtModule } from './jwt/jwt.module';
@NgModule({
  imports: [
    FlexLayoutModule,
    JwtModule,
    FilterNonAsciiModule,
    ...insertIf(environment.dev, MockDataModule.forRoot({ setup }, { delay: 500, apiBase: 'api' })),
  ],
  providers: [CustomBreakPointsProvider, ExternalLibrariesLoaderService],
})
export class CoreModule {}
