import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';
import { hasValue, ifElse, nonEmpty } from '@nl/utils';
import { allPass, constant, get, isString } from 'lodash/fp';

interface SanitizerBypasser {
  html: (html: string) => SafeHtml;
  script: (script: string) => SafeScript;
  style: (style: string) => SafeStyle;
  url: (url: string) => SafeUrl;
  resourceUrl: (resourceUrl: string) => SafeResourceUrl;
}
type SUPPORTED_TYPE = keyof SanitizerBypasser;

@Pipe({
  name: 'bypass',
})
export class BypassPipe implements PipeTransform {
  bypassMapping: SanitizerBypasser;
  constructor(private sanitizer: DomSanitizer) {
    this.bypassMapping = {
      html: sanitizer.bypassSecurityTrustHtml,
      script: sanitizer.bypassSecurityTrustScript,
      style: sanitizer.bypassSecurityTrustStyle,
      url: sanitizer.bypassSecurityTrustUrl,
      resourceUrl: sanitizer.bypassSecurityTrustResourceUrl,
    };
  }

  transform(
    value: string,
    type: SUPPORTED_TYPE = 'html'
  ): SafeHtml | SafeScript | SafeStyle | SafeUrl | SafeResourceUrl {
    return ifElse(
      allPass([hasValue, constant(nonEmpty(value)), constant(isString(value))]),
      (mapper: SanitizerBypasser[keyof SanitizerBypasser]) => mapper(value),
      constant(value),
      get(type, this.bypassMapping)
    );
  }
}
