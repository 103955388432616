import identity from 'lodash-es/identity';
import includes from 'lodash-es/includes';

export const inWhitelist = (whitelist: any[], mapper: any = identity) => (target: any): boolean =>
  includes(whitelist, mapper(target));
export const notInWhitelist = (whitelist: any[], mapper: any = identity) => (
  target: any
): boolean => !includes(whitelist, mapper(target));
export const inBlacklist = inWhitelist;
export const notInBlacklist = notInWhitelist;
