import { HttpRequest } from '@angular/common/http';
import find from 'lodash-es/find';
import { flow, last, split } from 'lodash/fp';
import { activate, checkActivate, currentInfo, mockPaymentInfo } from './account';
import { account, login, resend } from './auth.mock';
import { mockCampaignMetrics, mockShopifyCampaignDetail } from './campaign';
import { mockApplyCoupon, mockCoupons } from './coupon';
import { groupHistory, lists as gruopLists, members, membersView, segmentHistory } from './groups';
import { integrations } from './integration';
import { twmOrders } from './order';
import { mockBalance, mockFailCheckout, mockPricing } from './prepaid';
import { member, parameters, subscribersView } from './subscribers';
import { billingPlans, userPlan } from './user-plan';
import {
  mockCodeGetter,
  mockCodeVerifier,
  mockMfaClients,
  mockMfaDisable,
  mockMfaEnable,
  mockRegisterMfaClient,
} from './mfa';
import { mockSenders } from './senders';
import {
  mockBrandingsResponse,
  mockCreateBrandingResponse,
  mockPageListResponse,
} from './page.mock';

export default () => ({
  'POST login': login,
  'GET account/expired/info': account,
  'POST resend-activation': resend,
  'GET subscribers/groups': gruopLists,
  'GET subscribers/groups/history': groupHistory,
  'GET subscribers/segments/history': segmentHistory,
  'GET subscribers/groups/overview': membersView,
  'GET subscribers/groups/members': members,
  'POST contacts/imports/groups/.*/filter': 10,
  'POST contacts/.*': member,
  'GET subscribers/parameters': parameters,
  'GET subscribers/members.*': members,
  'GET subscribers/view': subscribersView,
  'GET subscribers/single': member,
  'POST activate': checkActivate,
  'POST activate/newsleopard': activate,
  'GET account/view': currentInfo,
  'GET twm/billing/orders': twmOrders,
  'GET twm/billing/order/.*': (req: HttpRequest<void>) => {
    const extractSn = flow(split('/'), last);
    return find(twmOrders, ['sn', extractSn(req.urlWithParams)]);
  },
  'GET twm/billing-plan': billingPlans,
  'GET analytics/subscriptionInfo': userPlan,
  'GET integration': integrations,

  'GET campaigns/(.*)/metrics': mockCampaignMetrics,
  'GET campaigns/(.*)/(.*)': mockShopifyCampaignDetail,
  'GET expired/coupon': mockCoupons,
  'POST expired/coupon/apply': mockApplyCoupon,
  'GET prepaid/expired/pricing': mockPricing,
  'GET prepaid/expired/balance': mockBalance,
  'POST prepaid/expired/checkout': mockFailCheckout,
  'GET account/expired/paymentInfo': mockPaymentInfo,
  'GET mfa-client': mockMfaClients,
  'POST mfa-client/enable': mockMfaEnable,
  'POST mfa-client/disable': mockMfaDisable,
  'POST mfa-client/register': mockRegisterMfaClient,
  'GET mfa-client/code': mockCodeGetter,
  'PATCH mfa-client/verify': mockCodeVerifier,

  'POST signupPages': () => ({}), // TODO: creat signup page mock
  'GET contacts/lists': [membersView],
  'GET senders/active': mockSenders,
  'GET brandings': mockBrandingsResponse,
  'POST brandings': mockCreateBrandingResponse,
  'POST signupPages/7/publish': () => ({}),
  'GET signupPages': mockPageListResponse,
});
