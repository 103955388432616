import { AbstractControl, FormGroup } from '@angular/forms';
export interface ConfigFormHelpers {
  form: FormGroup;
  control: (path: string) => AbstractControl;
  group: (path: 'blockStyles' | 'config' | string) => FormGroup;
  value: <V>(path: string) => V;
}

export const existingFormHelpers = (form: FormGroup): ConfigFormHelpers => {
  const control = (path: string) => form.get(path) as AbstractControl;
  return {
    form,
    control,
    group: path => form.get(path) as FormGroup,
    value: path => control(path).value,
  };
};

// control.statusChanges
//   .pipe(
//     startWith(control.status),
//     distinctUntilChanged((prev, curr) => !(prev === 'DISABLED' || curr === 'DISABLED')),
//     untilDestroyed(context)
//   )
//   .subscribe((status: string) => {
//     if (status === 'DISABLED') {
//       viewControl.disable();
//     } else {
//       viewControl.enable();
//     }
//   });
