import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  template: `
    <div class="notify-container">
      <i class="hint fa-solid fa-circle-exclamation"></i>
      <span class="notify__txt">
        請
        <a class="reload_href" [href]="currentPage">重整頁面</a>
        以取得新的版本
      </span>
      <i class="fa-solid fa-xmark btn-close" aria-hidden="true" (click)="dismiss()"></i>
    </div>
  `,
  styles: [
    `
      .notify-container {
        display: flex;
        align-items: center;

        min-width: 250px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .hint {
        color: #ffbe14;
        font-size: 24px;
        margin-right: 16px;
      }
      .reload_href {
        color: #3f7dbd;
        text-decoration: underline;
        margin-left: 4px;
        margin-right: 4px;
      }
      .notify__txt {
        flex: 1 1 auto;
      }
      .btn-close {
        color: #999999;
        cursor: pointer;
        padding-left: 8px;
      }
    `,
  ],
})
export class CheckUpdateNotifyComponent {
  currentPage = window.location.href;

  constructor(private ref: MatSnackBarRef<CheckUpdateNotifyComponent>) {}

  dismiss() {
    this.ref.dismiss();
  }
}
