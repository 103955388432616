<header class="header">
  <div class="header-wrapper">
    <a class="logo" href="https://newsleopard.com"
      ><img src="assets/images/logo-company.png" alt="newsleopard 電子豹"
    /></a>
  </div>
</header>
<section *ngIf="isIE" class="alert-section">
  <div class="alert-card">
    <i class="fa-solid fa-circle-exclamation"></i>
    <span class="text"
      >本網站不支援 IE 瀏覽器，請使用
      <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome</a>
      或其他瀏覽器，以完整體驗網站各項功能。</span
    >
  </div>
</section>

<main class="main-content">
  <h3 class="heading-m-bold mb-m">選擇適合你的產品</h3>
  <ul class="product-list">
    <li class="item">
      <p class="label">行銷人員操作用</p>
      <div class="card" [class.disabled]="!newsleopardEnabled" (click)="goProduct('newsleopard')">
        <div><img class="logo" src="assets/images/logo-nl-h.svg" /></div>
        <div>
          <img class="product-image" src="assets/images/product-nl.svg" />
        </div>
      </div>
      <ng-container *ngIf="!newsleopardEnabled; else nlEnabledTpl">
        <div class="nl-button nl-primary size-m" (click)="goActivate('newsleopard')">啟用</div>
        <p class="hint">啟用服務享有免費試用 30 天，天天 50 封</p>
      </ng-container>
      <ng-template #nlEnabledTpl>
        <div class="link-go-product" (click)="goProduct('newsleopard')">前往 Newsleopard</div>
      </ng-template>
    </li>
    <li class="item" *ngIf="surenotifyEnabled">
      <p class="label">工程師開發用</p>
      <div class="card" [class.disabled]="!surenotifyEnabled" (click)="goProduct('surenotify')">
        <div><img class="logo" src="assets/images/logo-sn-h.svg" /></div>
        <div>
          <img class="product-image" src="assets/images/product-sn.svg" />
        </div>
      </div>
      <ng-container *ngIf="!surenotifyEnabled; else snEnabledTpl">
        <div class="nl-button sn-primary size-m" (click)="goActivate('surenotify')">啟用</div>
        <p class="hint">啟用服務享有測試封數 200 封</p>
      </ng-container>
      <ng-template #snEnabledTpl>
        <div class="link-go-product" (click)="goProduct('surenotify')">前往 Surenotify</div>
      </ng-template>
    </li>
  </ul>
</main>

<footer class="footer">快豹科技有限公司 版權所有 © {{ thisYear }}</footer>
