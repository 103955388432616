import { Injector, Type } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dynamicPipe' })
export class DynamicPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(value: string, ...args: any) {
    const [pipe, pipeArgs] = args;
    if (!pipe) return value;

    const transformer = this.injector.get(pipe as Type<PipeTransform>);
    if (!transformer) return value;

    return pipeArgs ? transformer.transform(value, ...pipeArgs) : transformer.transform(value);
  }
}
