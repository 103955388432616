import faker from 'faker';
import { IntegrationsInfo } from './../src/app/module/integration/integration.model';

const hasGaIntegration = faker.random.boolean();
const hasViewId = hasGaIntegration ? faker.random.boolean() : false;
const hasPropertyId = hasGaIntegration ? faker.random.boolean() : false;
const hasShopifyIntegration = faker.random.boolean();
export const integrations: IntegrationsInfo = {
  integrationKey: '4028e15969055595016909eb22110000',
  ga: {
    hasIntegration: hasGaIntegration,
    hasViewId: hasViewId,
    hasPropertyId: hasPropertyId,
    googleAccount: faker.internet.email(),
    authorizationDate: faker.date.past().toISOString(),
    viewId: faker.random.number(9999).toString(),
    propertyId: faker.random.number(9999).toString(),
  },
  shopify: {
    hasIntegration: hasShopifyIntegration,
    account: 'goodgu@newsleopard.tw',
    authorizationDate: faker.date.past().toISOString(),
    shops: [
      {
        name: 'Shopify測試商店',
        domain: 'abc.com.tw',
      },
      {
        name: 'Shopify測試商店2',
        domain: 'abcddd.com.tw',
      },
    ],
  },
};
