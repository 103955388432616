import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProductGatewayComponent } from './product-gateway/product-gateway.component';

export const customerRoutes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'products',
    component: ProductGatewayComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('@nl/email/app/module/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('@nl/email/app/module/base.module').then(m => m.BaseModule),
  },
  {
    path: 'user-expired',
    loadChildren: () =>
      import('@nl/email/app/module/user-expired/user-expired.module').then(
        m => m.UserExpiredModule
      ),
  },
];

const APP_ROUTES: Routes = [
  ...customerRoutes,
  {
    path: '**',
    loadChildren: () =>
      import('@nl/email/app/module/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
