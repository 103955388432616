import type { Element, Node } from 'domhandler';
import flow from 'lodash-es/flow';
import uniq from 'lodash-es/uniq';
import { map } from 'lodash/fp';
import memoized from 'memoizee';

async function loadParserLibs() {
  const [htmlparser2] = await Promise.all([import('htmlparser2')]);
  return {
    htmlparser2,
  };
}

export const parseLinks = memoized(
  async (content: string): Promise<string[]> => {
    const { htmlparser2 } = await loadParserLibs();
    const { Parser, DomHandler, DomUtils } = htmlparser2;
    const { findAll } = DomUtils;

    let parseError: any;
    let parseResult: Node[] = [];
    const handler = new DomHandler((error, dom) => {
      parseError = error;
      parseResult = dom;
    });
    const parser = new Parser(handler);
    parser.write(content);
    parser.end();

    if (parseError) {
      console.error(parseError);
      return [];
    }

    // @ts-ignore
    const aTags = findAll(ele => ele.name === 'a' && ele.type === 'tag', parseResult);

    const toLink = (aTag: Element) => aTag.attribs['href'];
    return flow(map(toLink), uniq)(aTags);
  },
  { max: 3 }
);
