import { ValidatorFn, AbstractControl } from '@angular/forms';
import head from 'lodash-es/head';
import tail from 'lodash-es/tail';
import isEmpty from 'lodash-es/isEmpty';

const some = (validatorFns: ValidatorFn[]): ValidatorFn => {
  const validate =
    (validators: ValidatorFn[] = [], errors = {}): ValidatorFn =>
    (control: AbstractControl) => {
      const validator = head(validators);
      if (!validator) {
        return isEmpty(errors) ? null : errors;
      }
      const validation = validator(control);
      return validation ? validate(tail(validators), { ...errors, ...validation })(control) : null;
    };
  return validate(validatorFns, {});
};

export default some;
