import { cond } from 'lodash/fp';
import { isObservable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const catchHttpErrors = (handlers: any) =>
  catchError(errors => {
    // @ts-ignore
    const result = cond(handlers)(errors);
    return result ? (isObservable(result) ? result : of(result)) : throwError(errors);
  });
