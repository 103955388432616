export enum PayStatus {
  PENDING,
  COMPLETE,
  FAILURE,
  REWARD,
}

export interface TwmOrderAccounting {
  sn: string;
  status: PayStatus;
  chargePeriod: string;
  monthlyFee: number;
  quota: number;
  overageUnitPrice: number;
  overageFee: number;
  totalSent: number;
  totalCost: number;
  orderNo: string;
  payDate?: string;
  ui?: {
    failToClose?: boolean;
    title?: string;
  };
}
