import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export const isFormDisabled =
  () =>
  (source$: Observable<string>): Observable<boolean> => {
    return source$.pipe(
      distinctUntilChanged((prev, curr) => !(prev === 'DISABLED' || curr === 'DISABLED')),
      map(status => status === 'DISABLED')
    );
  };
