import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '@nl/email/app/shared/service/logger/logger.service';
import { environment } from '@nl/email/env';
import { assign, isString } from 'lodash-es';
import { Observable, iif, of, throwError } from 'rxjs';
import { concatMap, retryWhen, tap, timeout } from 'rxjs/operators';
import { AccountStore } from '../../module/layout';
import { AuthService } from '../../shared/service/auth/auth.service';
import errorMapping from './error-code-mapping';

const { AUTH_TYPE, siteDomain, timeoutThreshold } = environment;
const createLogger = (logger: LoggerService) => {
  const logRequest = (request: HttpRequest<any>) => {
    logger.groupCollapsed('----- http request interceptor -----');
    logger.group('request from interceptor');
    logger.info('%curl', 'color: #43BD52;font-weight: bold', request.url);
    logger.info('%cheaders', 'color: #43BD52;font-weight: bold', request.headers);
    logger.info('%cbody', 'color: #43BD52;font-weight: bold', request.body);
    logger.groupEnd();
    logger.groupEnd();
  };

  const logSuccessResponse = (event: HttpResponse<any>) => {
    logger.groupCollapsed('response from interceptor');
    logger.info('%cresponse body', 'color: #4675DD;font-weight: bold', event.body);
    logger.groupEnd();
  };

  const logFailureErrorMessage = (err: HttpErrorResponse) => {
    logger.group('request error happen from interceptor');
    logger.error('request error from interceptor ', err);
    logger.groupEnd();
  };

  return {
    logFailureErrorMessage,
    logRequest,
    logSuccessResponse,
  };
};

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  logRequest: any;
  logSuccessResponse: any;
  logFailureErrorMessage: any;

  constructor(
    private auth: AuthService,
    private logger: LoggerService,
    private router: Router,
    private account: AccountStore
  ) {
    const { logRequest, logSuccessResponse, logFailureErrorMessage } = createLogger(logger);
    this.logRequest = logRequest;
    this.logSuccessResponse = logSuccessResponse;
    this.logFailureErrorMessage = logFailureErrorMessage;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { logRequest } = this;
    const skipAuth = !!req.headers.get('noAuth');
    const skipRetry = !!req.headers.get('skipRetry');
    const request = skipAuth
      ? req.clone({ headers: req.headers.delete('noAuth') })
      : req.clone({ withCredentials: true });

    logRequest(request);
    if (req.method !== 'GET' || skipRetry) {
      const rawReq = request.clone({ headers: request.headers.delete('skipRetry') });
      return next.handle(rawReq).pipe(tap({ error: this.exceptionHandler(request) }));
    }

    return next.handle(request).pipe(
      tap({ error: this.exceptionHandler(request) }),
      timeout(timeoutThreshold),
      retryWhen(error => {
        return error.pipe(
          concatMap((e, i) => iif(() => e.name !== 'TimeoutError' || i > 3, throwError(e), of(e)))
        );
      })
    );
  }

  exceptionHandler(request: HttpRequest<any>) {
    const { logFailureErrorMessage } = this;
    return (err: any) => {
      if (!(err instanceof HttpErrorResponse)) {
        return;
      }
      const status = err.status;
      if (status === 0) {
        assign(err, { networkError: err.status === 0 });
        logFailureErrorMessage(err);
        return;
      }

      const errorJSON = isString(err.error) ? JSON.parse(err.error) : err.error;
      assign(
        err,
        {
          error: errorJSON,
          timeoutError: status === 504,
          gatewayError: status === 502,
          unknownError: status === 500,
        },
        errorMapping(errorJSON, err.status, request.body)
      );

      if (status === 401) {
        this.logger.info(
          '%cUnauthenticated request, save it to failureRequest of auth service',
          'color: grey;font-weight: bold'
        );
        this.auth.failureRequest$.next(request);
        this.account.reset();
        this.router.navigate(['/auth/login'], { replaceUrl: true });
      }

      // @ts-ignore
      if (err.activatedAccount) {
        // const authToken = err.headers.get('Authorization') || '';
        // const jwtToken = authToken.slice(AUTH_TYPE.length + 1);
        // unless(isEmpty, jwt => this.auth.refreshToken(jwt), jwtToken);
      }
      logFailureErrorMessage(err);
    };
  }
}
