import { InjectionToken } from '@angular/core';
export interface MockData {
  setup: () => object;
}

export interface MockInterceptorConfig {
  apiBase?: string;
  passThruUnknownUrl?: boolean;
  delay?: number;
}

export const MOCK_PROVIDER = new InjectionToken<MockData>('mock_provider');
export const MOCK_CONFIG = new InjectionToken<MockInterceptorConfig>('mock_interceptor_config');
