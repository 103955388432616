import forEach from 'lodash-es/forEach';
import identity from 'lodash-es/identity';
import isPlainObject from 'lodash-es/isPlainObject';
import kebabCase from 'lodash-es/kebabCase';

const alterCssVariables = (ops: (key: string, value: string) => void) => (
  variables: object,
  keyMapper: (key: string) => string = identity
) => {
  forEach(variables, (value, key) => {
    if (isPlainObject(value)) {
      return;
    }
    ops(`--${kebabCase(keyMapper(key))}`, value);
  });
};

export const addCssVariables = alterCssVariables((key, value) =>
  document.documentElement.style.setProperty(key, value)
);
export const removeCssVariables = alterCssVariables(key =>
  document.documentElement.style.removeProperty(key)
);
