import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { eq } from 'lodash/fp';

export type SUPPORT_THEME =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'tertiary'
  | 'gradient'
  | 'warn'
  | 'ghost'
  | 'ghost-primary'
  | 'nlC-primary';

type SIZE =
  | 'xs'
  | 's'
  | 'm'
  | 'ml'
  | 'l'
  | 'block'
  | 'icon-l'
  | 'icon-m'
  | 'icon-s'
  | 'icon-vertical';

@Component({
  selector: 'nl-button',
  templateUrl: `./button.component.html`,
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type: SUPPORT_THEME = 'primary';
  @Input() size: SIZE = 'm';
  @Input() loadingText = '載入中';
  @Input() sending = false;
  @Input() disabled = false;
  @Input() formAction = false;
  @Input() isAnchor = false;
  @Input() isComingSoon = false;
  @Input() href = '';
  @Input() id: string | null = null;
  @Input() round = false;
  @Input() isNew = false;
  @Output() onClick = new EventEmitter();

  get styleClass() {
    const { type, sending, size, isComingSoon, round } = this;
    return {
      btn: true,
      [type]: true,
      [size]: true,
      sending,
      isComingSoon,
      round,
    };
  }

  get buttonType() {
    return this.formAction ? 'submit' : 'button';
  }

  @HostListener('click', ['$event'])
  click($event: MouseEvent) {
    // FIXME: submit form by ENTER would trigger click event also
    // using clientX to check the event
    if (eq(0, $event.clientX) || this.notAllowed) {
      return;
    }

    this.onClick.next($event);
  }

  @HostBinding('style.pointer-events')
  get pointerEvents() {
    return this.notAllowed ? 'none' : 'auto';
  }

  get notAllowed() {
    return this.sending || this.disabled;
  }
}
