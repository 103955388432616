import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@nl/email/env';
import { Observable, of } from 'rxjs';
import { retry, shareReplay, switchMap } from 'rxjs/operators';
import { connect } from 'socket.io-client';
import { AccountQuery } from './state/account.query';

const { socketHost } = environment;
export type SOCKET_EVENTS =
  | 'MEMBER_IMPORT_PROCESSING_UPDATE'
  | 'ACCOUNT_UPDATE'
  | 'RECIPIENT_UPLOAD_PROCESSING_UPDATE';

@UntilDestroy()
@Injectable()
export class SocketService implements OnDestroy {
  private _socket$: Observable<SocketIOClient.Socket>;

  constructor(private account: AccountQuery) {}

  listen() {
    this._socket$ = new Observable<SocketIOClient.Socket>(observer => {
      const user = this.account.sn;
      const socket = connect(`${socketHost}?u=${user}`);
      socket.on('connect', () => observer.next(socket));
      socket.on('connect_timeout', () => observer.error('connect_timeout'));
      socket.on('connect_error', () => observer.error('connect_error'));

      return () => socket.disconnect();
    }).pipe(retry(3), shareReplay(), untilDestroyed(this));
    this._socket$.subscribe();
  }

  on<T = any>(event: SOCKET_EVENTS) {
    if (environment.dev) return of<T>();
    if (!this._socket$) {
      this.listen();
    }

    return this._socket$.pipe(
      switchMap(
        socket =>
          new Observable<T>(observer => {
            socket.on(event, payload => observer.next(payload));
            return () => socket.off(event);
          })
      )
    );
  }

  ngOnDestroy() {}
}
