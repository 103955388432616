import { RoleType } from '@nl/email/app/shared/model/auth.model';
export interface AccountInfo {
  sn: string;
  type: UserType;
  status: UserStatus;
  roles: RoleType[];
  hasCustom: boolean; // hasCustomPricing
  account: string;
  expiredDate: string;
  isNewsleopardActive?: boolean;
  isSmsActive?: boolean;
  remaining?: number;
  smsRemaining?: number;
  emailQuotaThreshold?: number;
  smsQuotaThreshold?: number;
  domainAuthenticationStatus: DomainAuthenticationStatus;
  mfaEnabled?: boolean;
}

export enum UserType {
  FREE_ACCOUNT_OLD = '1',
  PAID_ACCOUNT_OLD = '2',
  PAID_ACCOUNT_OLD_EXT = '3',
  PROJECT_OLD = '4',
  APP_WORKS_PROJECT = '5',
  FREE_ACCOUNT = '7',
  PAID_ACCOUNT = '8',
  PROJECT = '9',
  TWM_PROJECT = '10',
  NPO = '11',
}

export enum UserStatus {
  NORMAL,
  OVER_QUOTA,
  OVER_DAILY_QUOTA,
  OVER_MONTHLY_QUOTA,
  ABNORMAL,
  BANNED,
}

export enum DomainAuthenticationStatus {
  UNAUTHENTICATED = 0,
  AUTHENTICATED = 1,
  AUTHENTICATED_FAILED = 2,
}

export const UserTypes = {
  '1': '免費會員',
  '2': '付費會員',
  '3': '付費會員 (附)',
  '4': '專案合作',
  '5': 'Appworks 專案',
  '7': '免費會員 (2015)',
  '8': '付費會員 (2015)',
  '9': '專案合作 (大量專案)',
};

export const UserFilter = {
  '1': '免費',
  '2': '付費',
  '3': '付費',
  '4': '付費',
  '5': '付費',
  '7': '免費',
  '8': '付費',
  '9': '付費',
};
