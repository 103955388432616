import { GroupHistory, GroupView } from '@nl/email/app/module/subscribers/groups/groups.model';
import {
  SubscribersMember,
  SubscriberType,
} from '@nl/email/app/module/subscribers/subscribers.model';

export const membersView: GroupView = {
  sn: '40286e4c64abe2ea0164ac06dc930000',
  clickedRate: 10,
  openedRate: 4,
  name: '八月份 VIP',
  subscribedCnt: 2600,
  softBounceCnt: 1,
  hardBounceCnt: 0,
  complainedCnt: 2,
  unsubscribedCnt: 2,
  unsubscribedByOwnerCnt: 1,
  excludeCnt: 6,
  createDate: '2018-07-18T06:14:48Z',
};

export const lists: Array<GroupView> = [membersView, membersView, membersView];

export const groupHistory: Array<GroupHistory> = [
  {
    createDate: '2018-07-18T06:14:48Z',
    count: 20,
    type: 0,
    fileName: 'filename.csv',
  },
  { createDate: '2018-07-18T06:14:48Z', count: 20, type: 3 },
];

export const segmentHistory = [
  [
    {
      option: '欄位',
      filters: ['Email Domain', '是', 'gmail.com'],
    },
    {
      option: '活動',
      filters: ['已寄送', '活動名稱', '電子豹八月份 VIP 會員群組'],
    },
    {
      option: '互動成效',
      filters: ['平均開信率', '大於', '25%'],
    },
  ],
  [
    {
      option: '分類標籤',
      filters: ['包含', '電子豹'],
    },
    {
      option: '活動',
      filters: ['已寄送', '活動紀錄', '過去七天任一活動'],
    },
  ],
];

const SUBSCRIBERS: Array<Partial<SubscribersMember>> = [
  {
    email: 'ray@newsleopard.tw',
    name: 'Ray',
    parameter: {
      p1045203: '男',
      p8821451: 'PM',
      p9063683: '32',
      p3790278: '電子豹',
      p3790279: '',
      p3790210: '',
      p3790211: '',
      p3790212: '已婚',
    },
    date: '2018-06-04T14:23:00Z',
    sn: '40286e4c64abe2ea0164ac06dc930000',
    type: SubscriberType.SUBSCRIBED,
  },
  {
    email: 'clement@newsleopard.tw',
    name: 'CC',
    parameter: {
      p1045203: '男',
      p8821451: 'RD',
      p9063683: '29',
      p3790278: '電子豹',
      p3790279: '',
      p3790210: '',
      p3790211: '台灣',
      p3790212: '',
    },
    date: '2018-05-04T14:23:00Z',
    sn: '40286e4c64abe2ea0164ac06dc932222',
    type: SubscriberType.SUBSCRIBED,
  },
];
const SOFT_BOUNCE = [
  {
    email: 'ray@newsleopard.tw',
    date: '2018-07-04T14:23:00Z',
    sn: '40286e4c64abe2ea0164ac06dc930000',
    type: SubscriberType.SOFT_BOUNCE,
  },
];
const HARD_BOUNCE = [];
const COMPLAINED = [
  {
    email: 'ray@newsleopard.tw',
    campaignName:
      // tslint:disable-next-line:max-line-length
      '物之子靜打言效愛點月往著電入內的外把為企達成習大一市已照男縣學非不、導單苦。看過我一……另國傳起醫花思常人、究裝本，例運一此自價大員像議兒。快然名一高。解氣一技成提共師盡現隨小加表？不就花元軍說論一和都對。圖算女買以可成點城照盡級的城北人長解臺沒都，也驚充遊我汽！總轉頭人期們明對而之。',
    date: '2018-08-04T14:23:00Z',
    sn: '40286e4c64abe2ea0164ac06dc930000',
    type: SubscriberType.COMPLAINED,
  },
];
const UNSUBSCRIBED = [
  {
    email: 'ray@newsleopard.tw',
    campaignName:
      '現亞設這注，爭好受舉算把生有說快山利過裡在明看權做河化服望不夠外給服成，資政一教的黃下一了物道下自房水天高木，生要為房終神經質平下幾天問為量媽工大：大發其石，認亞己此總接一人巴每望過再，一我領是來時腦創場南向究綠場有有果：的響。',
    reason: '...',
    date: '2018-10-04T14:23:00Z',
    sn: '40286e4c64abe2ea0164ac06dc930000',
    type: SubscriberType.UNSUBSCRIBED,
  },
];
const UNSUBSCRIBED_BY_OWNER = SUBSCRIBERS;

export const members = function (req) {
  const type: SubscriberType = req.params.get('type');
  switch (type) {
    case SubscriberType.SUBSCRIBED:
      return SUBSCRIBERS;
    case SubscriberType.SOFT_BOUNCE:
      return SOFT_BOUNCE;
    case SubscriberType.HARD_BOUNCE:
      return HARD_BOUNCE;
    case SubscriberType.COMPLAINED:
      return COMPLAINED;
    case SubscriberType.UNSUBSCRIBED:
      return UNSUBSCRIBED;
    case SubscriberType.UNSUBSCRIBED_BY_OWNER:
      return UNSUBSCRIBED_BY_OWNER;
  }
};
