import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ifElse, map } from '@nl/utils';
import assign from 'lodash-es/assign';
import isArray from 'lodash-es/isArray';
import isPlainObject from 'lodash-es/isPlainObject';
import reduce from 'lodash-es/reduce';
import { Observable, Subscription, combineLatest, identity } from 'rxjs';
import { scan } from 'rxjs/operators';

export class NlSubscribeContext {
  public $implicit: any = null;
  public nlSubscribe: any = null;
}

@Directive({
  selector: '[nlSubscribe]',
})
export class SubscribeDirective implements OnInit, OnDestroy {
  private obs!: Observable<any>;
  private context: NlSubscribeContext = new NlSubscribeContext();
  private subscription!: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  @Input() set nlSubscribe(sourceObs: Observable<any> | Observable<any>[]) {
    if (this.obs === sourceObs) return;
    const obs: Observable<any>[] = ifElse(
      isPlainObject,
      map((nestedObs: Observable<any>, key: string | number) =>
        nestedObs.pipe(scan((acc, curr) => assign(acc, { [key]: curr }), {}))
      ),
      identity
    )(sourceObs);

    this.obs = combineLatest(obs);
    this.unsubscribe();
    this.subscription = this.obs.subscribe(value => {
      const subscribeValue = isPlainObject(sourceObs)
        ? reduce(value, (acc, curr) => ({ ...acc, ...curr }), {})
        : isArray(sourceObs)
        ? value
        : value[0];

      this.context.nlSubscribe = subscribeValue;
      this.cdr.markForCheck();
    });
  }

  unsubscribe() {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
