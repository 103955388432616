import isObject from 'lodash-es/isObject';
import reduce from 'lodash-es/reduce';
import set from 'lodash-es/set';
import { reduce as _reduce, flow, isArray, toPairs } from 'lodash/fp';

export const flatten: (source: { [key: string]: any }, joiner?: string) => Record<string, any> = (
  source,
  joiner = '.'
) =>
  reduce(
    source,
    (acc, value, key) => {
      if (!isObject(value) || isArray(value)) {
        return {
          ...acc,
          [key]: value,
        };
      }

      const children = flatten(source[key], joiner);
      return {
        ...acc,
        ...reduce(
          children,
          (_acc, child, childKey) => ({
            ..._acc,
            [`${key}${joiner}${childKey}`]: child,
          }),
          {}
        ),
      };
    },
    {}
  );

export const unflatten = flow([toPairs, _reduce((cum, [key, value]) => set(cum, key, value), {})]);
