import { AbstractControl } from '@angular/forms';
import isPlainObject from 'lodash-es/isPlainObject';
import { eq, isArray, map as _map, some } from 'lodash/fp';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import isBlank from './../fp/is-blank';
import formValueChanges from './form-changes-with-initial';

interface Forms {
  controls: AbstractControl[];
  requiredFields?: Observable<boolean>;
}

function requiredForms(forms: Forms | AbstractControl[]): Observable<boolean> {
  const basicUsage = isArray(forms);
  const controls = basicUsage ? forms : (forms as Forms).controls;
  const requiredFields = basicUsage ? undefined : (forms as Forms).requiredFields;

  const controlsChange = _map(
    control =>
      formValueChanges(control).pipe(
        map(value => (isPlainObject(value) ? some(isBlank) : isBlank)(value))
      ),
    controls
  );

  const invalid$ = requiredFields
    ? combineLatest([...controlsChange, requiredFields])
    : combineLatest(controlsChange);

  return invalid$.pipe(map(some(eq(true))), distinctUntilChanged<boolean>());
}

export default requiredForms;
