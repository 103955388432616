import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BooleanToChinesePipe } from './boolean-to-chinese.pipe';
import { BypassPipe } from './bypass/bypass.pipe';
import { DATE_CONFIG, DateTextPipe } from './date-text.pipe';
import { DevelopingDirective } from './developing/developing.directive';
import { DynamicPipe } from './dynamic.pipe';
import { SpaceDirective } from './space/space.directive';
import { SubscribeDirective } from './subscribe/subscribe.directive';
import { TemplateDirective } from './template.directive';
import { VarDirective } from './var.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SubscribeDirective,
    SpaceDirective,
    BypassPipe,
    TemplateDirective,
    DateTextPipe,
    DynamicPipe,
    VarDirective,
    DevelopingDirective,
    BooleanToChinesePipe,
  ],
  exports: [
    SubscribeDirective,
    SpaceDirective,
    BypassPipe,
    TemplateDirective,
    DateTextPipe,
    DynamicPipe,
    VarDirective,
    DevelopingDirective,
    BooleanToChinesePipe,
  ],
  // TODO: dynamic time offset support runtime changes,
  // ref: https://stackoverflow.com/questions/44287827/dynamically-change-locale-for-datepipe-in-angular-2/49675774#49675774
  // ref: https://github.com/angular/angular/issues/15039#issuecomment-494821739
  providers: [{ provide: DATE_CONFIG, useValue: { offset: '+0800' } }, DatePipe],
})
export class NlCoreModule {}
