import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import get from 'lodash-es/get';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '@nl/email/env';
import { AccountQuery, AccountService } from '../module/layout';
import { SocketService } from '../module/layout/socket.service';

type Product = 'newsleopard' | 'surenotify';

@UntilDestroy()
@Component({
  selector: 'nl-product-gateway',
  templateUrl: './product-gateway.component.html',
  styleUrls: ['./product-gateway.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SocketService],
})
export class ProductGatewayComponent implements OnInit {
  pageState;
  newsleopardEnabled = false;
  surenotifyEnabled = false;
  isIE: boolean;

  constructor(
    private account: AccountQuery,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private socket: SocketService,
    private accountService: AccountService
  ) {
    this.pageState = get(this.router.getCurrentNavigation(), 'extras.state', {});
  }

  ngOnInit() {
    this.newsleopardEnabled = this.account.hadActivateNewsleopardProduct();
    this.surenotifyEnabled = this.account.hadActivateSurenotifyProduct();
    this.socket.listen();
    this.refreshAccountOnSocket();
    this.checkIfIE();
    if (!this.surenotifyEnabled && this.newsleopardEnabled) {
      this.goProduct('newsleopard');
    }
  }

  goProduct(product: Product) {
    if (product === 'newsleopard') {
      this.newsleopardEnabled && this.router.navigate(['/dashboard']);
      return;
    }

    this.surenotifyEnabled && this.document.location.replace(this.surenotifyPage());
  }

  goActivate(product: Product) {
    if (product === 'newsleopard') {
      this.router.navigate(['/newsleopard/activate'], { replaceUrl: true, state: this.pageState });
      return;
    }

    this.document.location.replace(this.surenotifyPage('/activate'));
  }

  surenotifyPage(page: '/activate' | '/' = '/') {
    return `${environment.surenotifyConsole}/public/redirect?p=${page}`;
  }

  checkIfIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    this.isIE = msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  }

  refreshAccountOnSocket() {
    this.socket
      .on('ACCOUNT_UPDATE')
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.accountService.getAccountInfo();
        this.accountService.refreshToken().subscribe();
      });
  }

  get thisYear() {
    return new Date().getFullYear();
  }
}
