import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export default function httpsUrlValidator(allowedEmpty: boolean = false): ValidatorFn {
  const nonSecureUrlError = { nonSecureUrl: true };
  return function validator(control: AbstractControl<string>): ValidationErrors | null {
    const url = control.value.trim();
    if (allowedEmpty && !url) {
      return null;
    }
    try {
      const { protocol } = new URL(url);
      return protocol === 'https:' ? null : nonSecureUrlError;
    } catch (error) {
      return nonSecureUrlError;
    }
  };
}
