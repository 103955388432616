import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountQuery, AccountStore } from '@nl/email/app/module/layout';
import {
  ActivateRequest,
  ChangePasswordFromResetRequest,
  CheckActivateRequest,
  FacebookAuthRequest,
  FacebookBindingRequest,
  FacebookSignUpRequest,
  GoogleRecaptchaResponse,
  LoginRequest,
  LoginResponse,
  ResendActivationRequest,
  ResetPasswordRequest,
  SignUpRequest,
  SignUpResponse,
} from '@nl/email/app/shared/model/auth.model';
import { getApi } from '@nl/email/app/shared/utils/get-api';
import renameProps from '@nl/email/app/shared/utils/renameProps';
import { Observable, Subject, of } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import { AccountInfo } from './../../model/account.model';

@Injectable()
export class AuthService {
  failureRequest$ = new Subject<HttpRequest<any>>();
  private _redirectUrl: string;
  constructor(
    private http: HttpClient,
    private account: AccountStore,
    private accountQuery: AccountQuery,
    private router: Router
  ) {}

  validateRecaptcha(token: string): Observable<GoogleRecaptchaResponse> {
    return this.http
      .get<GoogleRecaptchaResponse>(getApi('/gRecaptcha'), { params: { token } })
      .pipe(share());
  }

  public login(body: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(getApi('/login'), body).pipe(share());
  }

  public signUp(body: SignUpRequest): Observable<SignUpResponse> {
    return this.http.post<SignUpResponse>(getApi('/sign-up'), body).pipe(share());
  }

  public resendActivation(body: ResendActivationRequest) {
    return this.http.post(getApi('/resend-activation'), body).pipe(share());
  }

  public checkActivate(body: CheckActivateRequest) {
    return this.http.post<AccountInfo>(getApi(`/activate`), body).pipe(share());
  }

  public activate(body: ActivateRequest) {
    return of(body).pipe(
      map(
        // @ts-ignore
        renameProps({
          members: 'contactsNumber',
          corp: 'corpName',
          name: 'contactName',
          mail: 'contactMailAddress',
          tel: 'contactTel',
        })
      ),
      switchMap(req => this.http.post(getApi(`/activate/newsleopard`), req)),
      share()
    );
  }

  public loginViaFacebook(body: FacebookAuthRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(getApi('/fb/auth'), body).pipe(share());
  }

  public signUpViaFacebook(body: FacebookSignUpRequest): Observable<SignUpResponse> {
    return this.http.post<SignUpResponse>(getApi('/fb/sign-up'), body).pipe(share());
  }

  public bindingWithFacebook(body: FacebookBindingRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(getApi('/fb/binding'), body).pipe(share());
  }

  public resetPassword(body: ResetPasswordRequest) {
    return this.http.post(getApi('/reset-password'), body).pipe(share());
  }

  public changePassword(body: ChangePasswordFromResetRequest) {
    return this.http.patch(getApi('/change-password'), body).pipe(share());
  }

  set redirectUrl(url: string) {
    this._redirectUrl = url;
  }

  get redirectUrl() {
    return (
      this._redirectUrl ||
      (this.accountQuery.hadActivateSurenotifyProduct() ? '/products' : '/dashboard')
    );
  }

  public resetRedirectUrl() {
    this._redirectUrl = null;
  }

  public logout() {
    this.http.post(getApi('/logout'), {}).subscribe({
      next: () => {
        this.account.reset();
        sessionStorage.clear();
        this.router.navigate(['/auth/login'], { replaceUrl: true });
      },
    });
  }
}
