import { FormGroup, FormControl } from '@angular/forms';
import { reduce } from 'lodash-es';

export const cloneFormGroup = (group: FormGroup) =>
  reduce(
    group.controls,
    (newGroup, control, key) => {
      newGroup.addControl(
        key,
        new FormControl({ value: control.value, disabled: control.disabled }, control.validator)
      );
      return newGroup;
    },
    new FormGroup({})
  );
