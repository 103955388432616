import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import { T, reduce as _reduce, always, cond, flow, get, isNil, negate, pickBy } from 'lodash/fp';
import when from './../fp/when';

// @ts-ignore
const reduce = _reduce.convert({ cap: false });
const nonNil = negate(isNil);

const propMatch = <V>(path: string, predicate: (value: V) => boolean) =>
  flow([get(path), predicate]);

const mergeErrors: (formGroup: AbstractControl) => ValidationErrors | null = flow([
  cond([
    [propMatch('controls', isNil), get('errors')],
    [
      T,
      flow([
        get('controls'),
        reduce(
          (errors: ValidationErrors | null, ctrl: AbstractControl, name: string) => ({
            ...errors,
            [name]: mergeErrors(ctrl),
          }),
          {}
        ),
      ]),
    ],
  ]),
  pickBy(nonNil),
  when(isEmpty, always(undefined)),
]);

export const allFormErrors = mergeErrors;
