import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AccountQuery, AccountService } from '@nl/email/app/module/layout';
import { when } from '@nl/utils';
import { eq } from 'lodash/fp';
import { Observable } from 'rxjs';
import { map, switchMapTo, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExpiredGuard {
  constructor(
    private account: AccountQuery,
    private router: Router,
    private accountService: AccountService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountService.fetchAccountInfo().pipe(
      switchMapTo(this.account.hadTrialEnded$),
      tap<boolean>(when(eq(true), () => this.router.navigate(['/user-expired']))),
      map(expired => !expired)
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
