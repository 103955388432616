import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { map } from 'lodash-es';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';
import { concatMap } from 'rxjs/operators';

const TINYMCE = 'assets/tinymce/tinymce.min.js';
const TINYMCE_SCRIPT_PATHS: string[] = [
  'assets/tinymce/plugins/code/plugin.min.js',
  'assets/tinymce/plugins/image/plugin.min.js',
  'assets/tinymce/plugins/imagetools/plugin.min.js',
  'assets/tinymce/plugins/table/plugin.min.js',
  'assets/tinymce/plugins/textcolor/plugin.min.js',
  'assets/tinymce/plugins/colorpicker/plugin.min.js',
  'assets/tinymce/plugins/fullscreen/plugin.min.js',
  'assets/tinymce/plugins/link/plugin.min.js',
  'assets/tinymce/plugins/fullpage/plugin.min.js',
  'assets/tinymce/lang/zh_TW.js',
];

@Injectable({
  providedIn: 'root',
})
export class ExternalLibrariesLoaderService implements OnDestroy {
  loadedLibries: { [url: string]: ReplaySubject<any> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: any) {}

  loadTincemce() {
    return this.loadScript(TINYMCE).pipe(
      concatMap(() => this.loadUILibraries(TINYMCE_SCRIPT_PATHS))
    );
  }

  loadUILibraries(scripts: string[] = [], styles: string[] = []): Observable<any> {
    return forkJoin([
      ...map(scripts, path => this.loadScript(path)),
      ...map(styles, path => this.loadStyles(`${path}.css`)),
    ]);
  }

  loadScript(url: string): Observable<any> {
    if (this.loadedLibries[url]) {
      return this.loadedLibries[url].asObservable();
    }

    const cache = new ReplaySubject<void>();
    this.loadedLibries[url] = cache;
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      cache.next();
      cache.complete();
    };

    this.document.body.appendChild(script);
    return cache.asObservable();
  }

  loadStyles(url: string): Observable<any> {
    if (this.loadedLibries[url]) {
      return this.loadedLibries[url].asObservable();
    }

    const cache = new ReplaySubject<void>();
    this.loadedLibries[url] = cache;
    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      cache.next();
      cache.complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);
    return cache.asObservable();
  }

  ngOnDestroy() {}
}
