import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ButtonModule } from '@nl/button';
import { NlCoreModule } from '@nl/core';
import { AppRoutingModule } from '@nl/email/app/app-routing.module';
import { AppComponent } from '@nl/email/app/app.component';
import { CoreModule } from '@nl/email/app/core/core.module';
import { GuestGuard } from '@nl/email/app/module/auth/guest.guard';
import { AuthService } from '@nl/email/app/shared/service/auth/auth.service';
import { ConsoleLoggerService } from '@nl/email/app/shared/service/logger/console-logger.service';
import { LoggerService } from '@nl/email/app/shared/service/logger/logger.service';
import { TitleService } from '@nl/email/app/title.service';
import { WebStorageModule } from '@nl/ngx-store';
import { CookieService } from 'ngx-cookie-service';
import { CoalescingComponentFactoryResolver } from './coalescing-component-factory-resolver.service';
import { CheckUpdateNotifyComponent } from './core/effects/check-update-notify.component';
import { CheckUpdateAvailableEffects } from './core/effects/check-update.effect';
import { EffectsModule } from './core/effects/effects.module';
import { ExpiredGuard } from './module/auth/expired.guard';
import { ProductGatewayComponent } from './product-gateway/product-gateway.component';

const providers: any[] = [
  { provide: LoggerService, useClass: ConsoleLoggerService },
  GuestGuard,
  ExpiredGuard,
  AuthService,
  CookieService,
  DatePipe,
  DecimalPipe,
  TitleService,
  CoalescingComponentFactoryResolver,
];
@NgModule({
  declarations: [AppComponent, ProductGatewayComponent, CheckUpdateNotifyComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule, // Import it after BrowserModule & Import just once.
    CoreModule,
    FlexLayoutModule,
    // Router
    NlCoreModule,
    AppRoutingModule,
    ButtonModule,
    // Begin:Customize Module
    // End:Customize Module
    // Begin: 3rd party module
    MatDialogModule,
    MatSnackBarModule,
    WebStorageModule,
    EffectsModule.register([CheckUpdateAvailableEffects]),
    AkitaNgDevtools.forRoot(),
    // End: 3rd party module
  ],
  bootstrap: [AppComponent],
  providers,
})
export class AppModule {
  constructor(titleService: TitleService, coalescingResolver: CoalescingComponentFactoryResolver) {
    titleService.init();
    coalescingResolver.init();
  }
}
