import { Injectable } from '@angular/core';

import { Logger } from '@nl/email/app/shared/service/logger/logger.service';
import { environment } from '@nl/email/env';
import { noop } from 'lodash-es';

export let isDebugMode = environment.isDebugMode;

const logInDebugMode = (createLogger: any) => (isDebugMode ? createLogger() : noop);

@Injectable()
export class ConsoleLoggerService implements Logger {
  // test
  get info() {
    return logInDebugMode(() => console.log.bind(console));
  }

  get warn() {
    return logInDebugMode(() => console.warn.bind(console));
  }

  get error() {
    return logInDebugMode(() => console.error.bind(console));
  }

  get group() {
    return logInDebugMode(() => console.group.bind(console));
  }

  get groupCollapsed() {
    return logInDebugMode(() => console.groupCollapsed.bind(console));
  }

  get groupEnd() {
    return logInDebugMode(() => console.groupEnd.bind(console));
  }
}
