import {
  SubscribersMember,
  SubscriberType,
} from '@nl/email/app/module/subscribers/subscribers.model';

export const subscribersView = {
  subscribedCnt: 2600,
  softBounceCnt: 1,
  hardBounceCnt: 2,
  complainedCnt: 0,
  unsubscribedCnt: 2,
  unsubscribedByOwnerCnt: 1,
  excludeCnt: 6,
};

export const parameters = {
  p1045203: '性別',
  p8821451: '職位',
  p9063683: '年齡',
  p3790278: '公司',
  p3790279: '電話',
  p3790210: '地址',
  p3790211: '國籍',
  p3790212: '婚姻狀況',
};

export const member: SubscribersMember = {
  email: 'ray@newsleopard.tw',
  name: 'Ray',
  parameter: {
    p1045203: '男',
    p8821451: 'PM',
    p9063683: '32',
    p3790278: '電子豹',
    p3790279: '',
    p3790210: '',
    p3790211: '',
    p3790212: '已婚',
  },
  groups: ['402823eb6b2c7378016b3f54000f0000', '4028f81a683ad49601683ae48e0f0000'],
  date: '2018-06-04T14:23:00Z',
  sn: '40286e4c64abe2ea0164ac06dc930000',
  type: SubscriberType.SUBSCRIBED,
};
