import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { actionWithLoading } from './../pipe/loading';

interface Forms {
  form: AbstractControl;
  loading?: BehaviorSubject<boolean>;
  action: (_: any) => Observable<any>;
  onInvalid?: Function;
  catchError?: boolean;
}

export function formSubmit(forms: Forms) {
  const { loading = new BehaviorSubject(false), action, onInvalid, catchError = true } = forms;

  return of(forms.form).pipe(
    tap(form => form.invalid && onInvalid && onInvalid()),
    // Angular take disabled control with `valid=true`
    filter(form => !form.invalid),
    actionWithLoading(loading, form => action(form.value), catchError)
  );
}
