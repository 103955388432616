export const mockPricing = () => [
  { tier: 2500, unitPrice: 0.4, pricingSn: '1', pricingType: 0 },
  { tier: 5000, unitPrice: 0.3, pricingSn: '2', pricingType: 0 },
  { tier: 10000, unitPrice: 0.26, pricingSn: '3', pricingType: 0 },
  { tier: 30000, unitPrice: 0.23, pricingSn: '4', pricingType: 0 },
  { tier: 50000, unitPrice: 0.2, pricingSn: '5', pricingType: 0 },
  { tier: 100000, unitPrice: 0.18, pricingSn: '6', pricingType: 0 },
  { tier: 200000, unitPrice: 0.16, pricingSn: '7', pricingType: 0 },
  { tier: 300000, unitPrice: 0.14, pricingSn: '8', pricingType: 0 },
  { tier: 500000, unitPrice: 0.1, pricingSn: '9', pricingType: 0 },
  { tier: 1000000, unitPrice: 0.09, pricingSn: '10', pricingType: 0 },
];

export const mockBalance = () => ({
  userSn: '40286ac0672eb2890167300f1dc10007',
  remaining: 19969,
  dailyFreeRemaining: 0,
  monthlyFreeRemaining: 0,
  freeUserRemaining: 0,
  totalRemaining: 19969,
});

export const mockFailCheckout = () => {
  return {
    _status: 400,
    code: 4005003,
  };
};
