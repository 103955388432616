import { Renderer2 } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import endsWith from 'lodash-es/endsWith';
import isString from 'lodash-es/isString';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface HtmlToExport {
  selector?: string;
  html?: string;
  filename: string;
  render: Renderer2;
}

export interface HtmlExport {
  save: (file: 'pdf' | 'png') => Observable<void>;
}

const EXPORT_CANVAS_ID = '__nl-export__';

function createNodeWithSandboxRender(render: Renderer2, content: string): Promise<HTMLElement> {
  return new Promise(solve => {
    const canvas = document.createElement('iframe');
    render.setAttribute(canvas, 'id', EXPORT_CANVAS_ID);
    render.setAttribute(canvas, 'srcdoc', content);
    render.setAttribute(canvas, 'referrerPolicy', 'no-referrer');
    render.setStyle(canvas, 'width', '100%');
    /* 
      hide the canvas by css filter which the html2canvas does not support, but the browser does. 
      you will not see iframe in the page, but html2canvas will render it 
    */
    render.setStyle(canvas, 'filter', 'opacity(0)');
    render.setStyle(canvas, 'position', 'absolute');
    render.setStyle(canvas, 'left', '-9999px');

    canvas.srcdoc = content;
    canvas.onload = () => {
      const canvasDocument = canvas.contentDocument || canvas.contentWindow?.document;
      canvas.style.height = `${canvasDocument!.body.scrollHeight}px`;
      solve(canvasDocument!.querySelector('html')!);
    };

    render.appendChild(document.body, canvas);
  });
}

function getRenderNode({ selector, html, render }: HtmlToExport): Promise<HTMLElement> {
  return new Promise(resolve => {
    const targetEl = selector && document.querySelector(selector);

    if (targetEl) {
      resolve(targetEl as HTMLElement);
      return;
    }

    return createNodeWithSandboxRender(render, html!).then(resolve);
  });
}
export const htmlExport = (props: HtmlToExport): Observable<HtmlExport> => {
  const { render, filename } = props;

  const imageExporter = (dataURL: string): Promise<void> | undefined => {
    const link: HTMLAnchorElement = render.createElement('a');
    if (!isString(link.download)) {
      window.open(dataURL);
      return;
    }

    link.href = dataURL;
    link.download = endsWith(filename, '.png') ? filename : `${filename}.png`;
    render.appendChild(document.body, link);
    link.click();
    render.removeChild(document.body, link);

    return Promise.resolve();
  };

  const pdfExporter = (dataURL: string, sourceElement: HTMLElement): Promise<void> => {
    const compWidth = sourceElement.offsetWidth;
    const compHeight = sourceElement.offsetHeight;
    const orientation = compWidth >= compHeight ? 'l' : 'p';
    const pdf = new jsPDF({ orientation, unit: 'px' });
    pdf.internal.pageSize.width = compWidth;
    pdf.internal.pageSize.height = compHeight;
    pdf.addImage(dataURL, 'PNG', 0, 0, compWidth, compHeight);
    const destination = endsWith(filename, '.pdf') ? filename : `${filename}.pdf`;
    return pdf.save(destination, { returnPromise: true });
  };

  const replaceImages = (target: HTMLElement) => {
    target.querySelectorAll('img').forEach(img => {
      if (!img.src) return;
      render.setAttribute(img, 'crossOrigin', 'anonymous');
      img.src = img.src + '?time=' + new Date().valueOf();
    });
    return target;
  };

  return new Observable(observer => {
    const cleanup = () => observer.complete();
    const targetNode = getRenderNode(props);

    targetNode.then(replaceImages).then((target: any) => {
      html2canvas(target, { useCORS: true, allowTaint: false })
        .then(canvas => canvas.toDataURL('image/png'))
        .then(
          imageDataURL =>
            observer.next({
              save: (file: 'pdf' | 'png') =>
                from(
                  file === 'pdf' ? pdfExporter(imageDataURL, target)! : imageExporter(imageDataURL)!
                ).pipe(tap(cleanup, cleanup)),
            }),
          error => {
            observer.error(error);
            cleanup();
          }
        );
    });
    return () => {
      const canvas = document.getElementById(EXPORT_CANVAS_ID) as HTMLElement;
      if (canvas) {
        render.removeChild(document.body, canvas);
      }
    };
  });
};
