import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isEmpty } from 'lodash-es';
// tslint:disable-next-line:max-line-length
const CHINESE_REG = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;

export const noChinese = (address: string) => !CHINESE_REG.test(address);

export default function noChineseValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control?.value;
    const isWellFormat = noChinese(value);
    return isEmpty(value) || isWellFormat ? null : { email: { value } };
  };
}
