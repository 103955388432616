import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { firstWithKey, hasValue, ifElse, renameProps, unless, when } from '@nl/utils';
import includes from 'lodash-es/includes';
import isEmpty from 'lodash-es/isEmpty';
import isNumber from 'lodash-es/isNumber';
import {
  always,
  assign,
  curry,
  get,
  getOr,
  isArray,
  isFunction,
  omit,
  overSome,
  pipe,
  size,
  startsWith,
} from 'lodash/fp';
import { Observable, of, throwError } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { MockData, MockInterceptorConfig, MOCK_CONFIG, MOCK_PROVIDER } from './mock-data.model';

const slice = curry((start: number, source: string) => source.slice(start));

@Injectable({ providedIn: 'root' })
export class MockDataInterceptor implements HttpInterceptor {
  constructor(
    @Inject(MOCK_PROVIDER) private mockProvider: MockData,
    @Inject(MOCK_CONFIG) private config: MockInterceptorConfig
  ) {}

  getPathname(url: string) {
    try {
      return new URL(url).pathname;
    } catch (e) {
      return url;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { delay: delayTime = 500, apiBase = '', passThruUnknownUrl = true } = this.config || {};
    const { method, url, headers } = req;
    if (includes(url, 'assets')) {
      return next.handle(req);
    }

    const pathname = pipe(
      this.getPathname,
      when(_ => hasValue(apiBase), slice(size('/' + apiBase + '/')))
    );
    const path = `${method} ${pathname(url)}`;
    const mocks = this.mockProvider.setup();

    const hitMock = mocks[path] ? mocks[path] : firstWithKey(key => !!path.match(key + '$'), mocks);
    if (isEmpty(hitMock) && !isFunction(hitMock) && passThruUnknownUrl) {
      console.warn('no matching pattern with path', path, 'from mock', mocks, this.config);
      return next.handle(req);
    }
    const mockResp = isFunction(hitMock) ? hitMock(req) : hitMock;

    if (!mockResp && passThruUnknownUrl) {
      return next.handle(req);
    }

    const status = ifElse(isArray, always(200), ({ _status: status }) =>
      isNumber(status) && status > 200 ? status : 200
    )(mockResp);
    const attachHeaders = pipe(getOr({}, 'headers'), h => new HttpHeaders(h));

    const respHeaders: HttpHeaders = ifElse(
      overSome([isArray, (body: any) => body.count > 0]),
      items => new HttpHeaders({ 'x-total-count': '' + size(items) }),
      attachHeaders
    )(mockResp);
    const body = unless(isArray, omit(['_status', 'headers', 'count']))(mockResp);

    const isOk = pipe(get('status'), startsWith('2'));

    const response = { status, body, headers: respHeaders };
    return ifElse(
      isOk,
      resp => of(new HttpResponse(resp)).pipe(delay(delayTime)),
      resp =>
        of(resp).pipe(
          delay(delayTime),
          map(renameProps({ body: 'error' })),
          map(assign({ url: url })),
          mergeMap(err => throwError(new HttpErrorResponse(err)))
        )
    )(response);
  }
}
