import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { formStatusChanges } from './form/form-status-change';
import { isFormDisabled } from './pipe/isFormDisabled';

export const syncStatusDisabled = (
  source: AbstractControl,
  target: AbstractControl,
  context: any
): Subscription => {
  return formStatusChanges(source)
    .pipe(isFormDisabled(), untilDestroyed(context))
    .subscribe(disabled => {
      if (disabled) {
        target.disable();
      } else {
        target.enable();
      }
    });
};
