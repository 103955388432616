import assign from 'lodash-es/assign';
import has from 'lodash-es/has';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isPlainObject from 'lodash-es/isPlainObject';
import map from 'lodash-es/map';
import negate from 'lodash-es/negate';
import reduce from 'lodash-es/reduce';
import { T, cond, flow, get } from 'lodash/fp';
import { unapply } from './fp/unapply';
const SOURCE = 0;
const NEW_VALUES = 1;
const PROP = 0;

type ReplaceNestedProps = (
  source: Record<string, any>,
  newValues: { [key: string]: string | number }
) => any;

export const replaceNestedProps: ReplaceNestedProps = flow([
  unapply,
  cond([
    [flow([get(NEW_VALUES), isEmpty]), get(SOURCE)],
    [flow([get(SOURCE), negate(isPlainObject)]), get(SOURCE)],
    [
      T,
      ([source, newValues]: [Record<string, any>, Record<string, string | number>]) =>
        reduce(
          source,
          (config, prop, key) => assign(config, { [key]: getValue(prop, newValues, key) }),
          {}
        ),
    ],
  ]),
]);

const getValue = flow([
  unapply,
  cond([
    [
      flow([get(PROP), isPlainObject]),
      ([prop, newValues]: [Record<string, any>, any]) => replaceNestedProps(prop, newValues),
    ],
    [
      flow([get(PROP), isArray]),
      ([prop, newValues]: [any[], Record<string, string | number>]) =>
        map(prop, item => replaceNestedProps(item, newValues)),
    ],
    [
      ([_, newValues, key]: [any, Record<string, string | number>, string]) => has(newValues, key),
      ([_, newValues, key]: [any, Record<string, string | number>, string]) => newValues[key],
    ],
    [T, get(PROP)],
  ] as any[]),
]);
