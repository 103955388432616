export const mockBrandingsResponse = {
  brandings: [
    {
      sn: 30,
      name: 'test1',
      landingPages: [
        {
          sn: 1748,
          userSn: '4028811687d325d00187e01445900000',
          id: 'index',
          name: 'dd',
          status: 3,
          title: 'test',
          description: 'test',
          favicon: 'https://nlstatic.newsleopard.de/system-resources/images/favicon-company.png',
          webIndexing: true,
          snippets: '{}',
          unPublishingScheduleType: 'ETERNAL',
          unPublishingDate: '2024-08-21T09:05:38.000+0000',
          unPublishingTimezone: 21,
          ogEnabled: false,
          gaEnabled: false,
          fbEnabled: false,
          errorPageUrl: 'https://newsleopard.com',
          endDate: '2024-08-21T09:22:47.000+0000',
          publishDate: '2024-08-21T09:05:59.000+0000',
          createDate: '2024-08-21T09:05:46.000+0000',
          updateDate: '2024-08-21T09:22:56.000+0000',
          expired: true,
          published: false,
          unpublished: false,
          typeName: 'landingPage',
          pageContentWellFormat: true,
          storagePath: 'test1/index/index.html',
          brandName: 'test1',
        },
        {
          sn: 1757,
          userSn: '4028811687d325d00187e01445900000',
          id: 'test11',
          name: 'ddd',
          status: 3,
          title: 'test',
          description: 'test',
          favicon: 'https://nlstatic.newsleopard.de/system-resources/images/favicon-company.png',
          webIndexing: true,
          snippets: '{}',
          unPublishingScheduleType: 'ETERNAL',
          unPublishingDate: '2024-08-21T09:16:49.000+0000',
          unPublishingTimezone: 21,
          ogEnabled: false,
          gaEnabled: false,
          fbEnabled: false,
          errorPageUrl: 'https://newsleopard.com',
          endDate: '2024-08-21T09:22:39.000+0000',
          publishDate: '2024-08-21T09:17:04.000+0000',
          createDate: '2024-08-21T09:16:53.000+0000',
          updateDate: '2024-08-21T09:22:56.000+0000',
          expired: true,
          published: false,
          unpublished: false,
          typeName: 'landingPage',
          pageContentWellFormat: true,
          storagePath: 'test1/test11/index.html',
          brandName: 'test1',
        },
        {
          sn: 1758,
          userSn: '4028811687d325d00187e01445900000',
          id: 'test',
          name: 'dd',
          status: 3,
          title: 'test',
          description: 'test',
          favicon: 'https://nlstatic.newsleopard.de/system-resources/images/favicon-company.png',
          webIndexing: true,
          snippets: '{}',
          unPublishingScheduleType: 'ETERNAL',
          unPublishingDate: '2024-08-21T09:21:20.000+0000',
          unPublishingTimezone: 21,
          ogEnabled: false,
          gaEnabled: false,
          fbEnabled: false,
          errorPageUrl: 'https://newsleopard.com',
          endDate: '2024-08-21T09:22:31.000+0000',
          publishDate: '2024-08-21T09:21:36.000+0000',
          createDate: '2024-08-21T09:21:25.000+0000',
          updateDate: '2024-08-21T09:22:56.000+0000',
          expired: true,
          published: false,
          unpublished: false,
          typeName: 'landingPage',
          pageContentWellFormat: true,
          storagePath: 'test1/test/index.html',
          brandName: 'test1',
        },
        {
          sn: 1759,
          userSn: '4028811687d325d00187e01445900000',
          id: 'index1',
          name: 'dd',
          status: 1,
          title: 'test',
          description: 'test',
          favicon: 'https://nlstatic.newsleopard.de/system-resources/images/favicon-company.png',
          webIndexing: true,
          snippets: '{}',
          unPublishingScheduleType: 'ETERNAL',
          unPublishingDate: '2024-08-21T09:23:00.000+0000',
          unPublishingTimezone: 21,
          ogEnabled: false,
          gaEnabled: false,
          fbEnabled: false,
          errorPageUrl: 'https://newsleopard.com',
          endDate: '2024-08-21T09:23:55.000+0000',
          publishDate: '2024-08-21T09:23:30.000+0000',
          createDate: '2024-08-21T09:23:07.000+0000',
          updateDate: '2024-08-22T05:10:41.000+0000',
          expired: true,
          published: true,
          unpublished: false,
          typeName: 'landingPage',
          pageContentWellFormat: true,
          storagePath: 'test1/index1/index.html',
          brandName: 'test1',
        },
        {
          sn: 1770,
          userSn: '4028811687d325d00187e01445900000',
          id: '123',
          name: 'test',
          status: 3,
          title: 'test',
          description: 'test',
          favicon: 'https://nlstatic.newsleopard.de/system-resources/images/favicon-company.png',
          webIndexing: true,
          snippets: '{}',
          unPublishingScheduleType: 'ETERNAL',
          unPublishingDate: '2024-08-22T05:12:11.000+0000',
          unPublishingTimezone: 21,
          ogEnabled: false,
          gaEnabled: false,
          fbEnabled: false,
          errorPageUrl: 'https://newsleopard.com',
          endDate: '2024-08-22T05:17:01.000+0000',
          publishDate: '2024-08-22T05:12:38.000+0000',
          createDate: '2024-08-22T05:12:22.000+0000',
          updateDate: '2024-08-22T05:17:07.000+0000',
          expired: true,
          published: false,
          unpublished: false,
          typeName: 'landingPage',
          pageContentWellFormat: true,
          storagePath: 'test1/123/index.html',
          brandName: 'test1',
        },
      ],
    },
  ],
  capacity: 3,
};

export const mockCreateBrandingResponse = {
  name: 'test',
  sn: 1,
};

export const mockPageListResponse = req => {
  const publishStatus = req.params.map.get('status')[0];
  const statusAndDataMapper = {
    PUBLISHED: mockPageInfoPublished,
    UNPUBLISHED: mockPageInfoUnpublished,
    DRAFT: mockPageInfoDraft,
  };
  return statusAndDataMapper[publishStatus];
};

export const mockEmptyPageInfo = { capacity: 0, pages: [] };

export const mockPageInfoPublished = {
  capacity: 3,
  pages: [
    {
      sn: 1844,
      name: 'test',
      brandingName: 'test1',
      url: 'https://test1.1landing.net/test123test',
      status: 1,
      screenPageViews: 0,
      subscriptions: 0,
      conversionRate: 0,
      unPublishingDate: '2024-08-23T01:14:41Z',
      unPublishingScheduleType: 'ETERNAL',
      publishDate: '2024-09-23T09:54:02Z',
      updateDate: '2024-09-23T09:54:02Z',
    },
    {
      sn: 1759,
      name: 'dd',
      brandingName: 'test1',
      url: 'https://test1.1landing.net/index1',
      status: 1,
      screenPageViews: 0,
      subscriptions: 0,
      conversionRate: 0,
      // TODO: confirm with backend about the correct field names
      // engagementFields: [
      //   { label: 'screenPageViews', value: 0, name: '瀏覽次數' },
      //   { label: 'subscriptions', value: 0, name: '訂閱數' },
      //   { label: 'conversionRate', value: 0, name: '轉換率' },
      // ],
      unPublishingDate: '2024-08-21T09:23:00Z',
      unPublishingScheduleType: 'ETERNAL',
      publishDate: '2024-08-21T09:23:30Z',
      updateDate: '2024-08-22T05:10:41Z',
      endDate: '2024-08-21T09:23:55Z',
    },
  ],
};

export const mockPageInfoUnpublished = {
  ...mockPageInfoPublished,
  pages: mockPageInfoPublished.pages.map(page => ({
    ...page,
    name: page.name + ' UNPUBLISHED',
    status: 2,
  })),
};

export const mockPageInfoDraft = {
  ...mockPageInfoPublished,
  pages: mockPageInfoPublished.pages.map(page => ({
    ...page,
    name: page.name + ' DRAFT',
    status: 0,
  })),
  capacity: 3,
};
