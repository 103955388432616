import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { CheckUpdateNotifyComponent } from './check-update-notify.component';
import { createEffect } from './create-effect';

@Injectable({ providedIn: 'root' })
export class CheckUpdateAvailableEffects {
  DURATION = 5 * 60_000;

  checkUpdateAvailable$ = createEffect(() =>
    interval(this.DURATION).pipe(
      switchMap(() =>
        this.http.get(window.location.href, {
          headers: { Accept: 'text/html' },
          params: { t: new Date().getTime() + '' },
          responseType: 'text',
        })
      ),
      map(this.extractRumtimeHash),
      filter(Boolean),
      pairwise(),
      filter(([prev, curr]) => prev !== curr),
      tap(() => {
        this.snackbar.openFromComponent(CheckUpdateNotifyComponent, {
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
      })
    )
  );

  constructor(private http: HttpClient, private snackbar: MatSnackBar) {}

  extractRumtimeHash(html: string): string | undefined {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const script = doc.querySelector('script[src*="runtime"]') as HTMLScriptElement;

    if (script && script.src) {
      const match = script.src.match(/runtime.*\.([a-f0-9]{5,40})\.js$/);
      return match ? match[1] : undefined;
    }

    return undefined;
  }
}
